import React, {useState, useEffect, useContext, useRef, lazy} from 'react';

import {
    Input,
    Form,
    Button,
    Table,
    Row,
    Space,
    message,
    Descriptions,
    Typography,
    Select,
    DatePicker,
    Tooltip,
    Modal,
    Col,
} from 'antd';
import {LeftOutlined} from '@ant-design/icons';
import moment from 'moment/moment';
import {useHistory, useLocation} from 'react-router-dom';

import {getApiWithAuthToken, postApiWithAuthToken} from 'api';

import InvoiceView from './InvoiceView';
import CollectionInvoiceView from './CollectionView';

import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {convertLocalToUTC} from 'helpers/utcTime';

const SalesSummaryModal = lazy(() => import('./SalesSummaryModal'));

const {Text} = Typography;
const {TextArea} = Input;
const {Option} = Select;

let text = Date.now();

const NewSaleEntry = () => {
    const [form] = Form.useForm();
    const history = useHistory();

    //salescode
    const sales_code = new URLSearchParams(useLocation().search).get(
        'sales_code',
    );

    //Table States

    const [route, setRoute] = useState();
    const [customer, setCustomer] = useState({});

    const [multiSalesTypeAllowed, setMultiSalesTypeAllowed] = useState(false);
    const [lockedSalesType, setLockedSalesType] = useState(null);

    const [routeCode, setRouteCode] = useState();
    const [selectedRoute, setSelectedRoute] = useState({});
    const [salesman, setSalesman] = useState([]);
    const [selectedSalesman, setSelectedSalesman] = useState(null);
    const [routeName, setRouteName] = useState('');
    const [routeDet, setRouteDet] = useState([]);
    const [companyDetail, setCompanyDetail] = useState([]);
    const [transType, setTransType] = useState();
    const [routeEInvoiceEnable, setRouteEInvoiceEnable] = useState(false);
    const [eInvoiceModalVisible, setEInvoiceModalVisible] = useState(false);
    const [grSelected, setGrSelected] = useState(false);
    const [selectedSalesType, setSelectedSalesType] = useState(null);
    const [billingReference, setBillingReference] = useState(null);
    const [grSalesDetails, setGrSalesDetails] = useState([]);
    const [partialInvoice, setPartialInvoice] = useState(false);
    const [grQuantityValidation, setGrQuantityValidation] = useState(true);
    const [amendSameProduct, setAmendSameProduct] = useState(false);

    const [transName, setTransName] = useState('');
    const [confirmFinal, setConfirmFinal] = useState(false);

    const [vatRegNo, setVatRegNo] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [collectionData, setCollectionData] = useState({});
    const [currencyDet, setCurrencyDet] = useState([]);

    const [currency, setCurrency] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedCurrencyName, setSelectedCurrencyName] = useState('');
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('');

    const [roundingRule, setRoundingRule] = useState('');
    const [roundingPrecision, setRoundingPrecision] = useState(0);
    const [currencyId, setCurrencyId] = useState();

    const [startTime, setStartTime] = useState(null);

    const [customerDet, setCustomerDet] = useState([]);
    const [salesType, setSalesType] = useState([]);
    const [remarks, setRemarks] = useState('');

    const [confirm, setConfirm] = useState(false);
    const [custData, setCustData] = useState(true);

    const [productId, setSelectProductId] = useState();
    const [editable, setEditable] = useState(false);
    const [collectionModalVisible, setCollectionModalVisible] = useState(false);
    const [collectionFinal, setCollectionFinal] = useState(false);

    const [requestData, setRequestData] = useState(null);

    const [draftSummary, setDraftSummary] = useState([]);

    const handleEinvoiceModalCancel = () => {
        setEInvoiceModalVisible(false);
    };

    //get salesdraftsummary

    function getDraftSummary() {
        getApiWithAuthToken(
            `/api/v1/admin/salesSummary/draft?invoice_no=${sales_code}`,
        ).then(res => {
            if (res.data && res.data.drafts.length > 0) {
                setDraftSummary(res.data.drafts);
            }
        });
    }

    //get sales details draft
    const getDraftDetailsList = () => {
        getApiWithAuthToken(
            `/api/v1/admin/salesDetails/draft?invoice_no=${sales_code}`,
        ).then(res => {
            if (!res.error && res.data.length) {
                const formattedData = res.data.map(each => ({
                    key: each.id,
                    sales_code: each.sales_code,
                    sales_type: each.sales_type,
                    sales_type_id: each.sales_type_id,
                    salesType: each.sales_type,
                    product: {
                        id: each.product,
                        name: each.product_name,
                        code: each.product_code,
                    },
                    multipack: {
                        id: each.multipack_id,
                        name: each.multipack_name,
                        code: each.multipack_code,
                        multiplier: each.multiplier,
                        base_multipack_id: each.base_multipack_id,
                        base_multipack_code: each.base_multipack_code,
                        base_multipack_name: each.base_multipack_name,
                    },

                    group_id: each.group_id,
                    group_code: each.group_code,
                    group_name: each.group_name,
                    quantity: each.multipack_quantity,

                    salePrice: each.sale_price,
                    discount:
                        each.discount_amount !== '' ? each.discount_amount : 0,
                    max_discount: each.max_discount,
                    discount_perc: each.discount_perc,
                    tax_1: each.tax_1,
                    tax_2: each.tax_2,
                    tax_perc: each.tax_1 + each.tax_2,

                    total: each.net_amount,
                    cost_price: each.cost_price,
                    price_id: each.price_id,
                    min_sales: each.min_sales,
                    total_taxable_amount: each.total_taxable_amount,
                    tax_amount: each.tax_amount,

                    product_price: each.product_price,

                    fromApi: true,
                }));
                setDataSource(formattedData);
                setShowTable(true);
                setConfirm(true);
            } else {
                if (res.errors) {
                    Object.values(res.errors).forEach(each =>
                        message.error(each.msg),
                    );
                } else {
                }
            }
        });
    };

    useEffect(() => {
        getDraftSummary();
        getDraftDetailsList();
    }, [sales_code]);

    //get vat and vat registration details
    useEffect(() => {
        if (customer) {
            try {
                getApiWithAuthToken(`/api/v1/admin/customers/${customer.id}`)
                    .then(res => {
                        if (!res.error && res.data) {
                            const {
                                rounding_rule,
                                rounding_precision,
                                def_currency,
                                def_currency_id,
                            } = res.data;
                            setRoundingRule(rounding_rule);
                            setRoundingPrecision(rounding_precision);

                            setCurrency(def_currency);
                            setCurrencyId(def_currency_id);
                            setTransType(res.data.transaction_types);

                            // Set initial currency details
                            const initialCurrency = currencyDet.find(
                                currency => currency.id === def_currency_id,
                            );
                            if (initialCurrency) {
                                setSelectedCurrency(initialCurrency.id);
                                setSelectedCurrencyName(initialCurrency.name);
                                setSelectedCurrencyCode(initialCurrency.code);
                            }
                        }
                    })
                    .catch(error => {});
            } catch (error) {}
        }
    }, [customer, currencyDet]);

    //company Details

    function getCompanyDetails() {
        getApiWithAuthToken('/api/v1/admin/company').then(res => {
            if (res.data) {
                setCompanyDetail(res.data);
                setVatRegNo(res?.data?.vat_reg_no);
                // Assuming multi_sales_type_in_single is part of the response
                setMultiSalesTypeAllowed(
                    res.data?.multi_sales_type_in_single ?? false,
                );

                // Assuming  amend_same_prod is part of the response
                setAmendSameProduct(res.data?.amend_same_prod ?? false);
            } else {
                setMultiSalesTypeAllowed(false);
                setAmendSameProduct(false);
            }
        });
    }

    //currency List
    function getCurrency() {
        getApiWithAuthToken('/api/v1/admin/companyCurrencies').then(res => {
            if (res.data?.length > 0) {
                setCurrencyDet(res.data);
            } else {
                setCurrencyDet([]);
            }
        });
    }

    // final confirm
    const FinalSubmit = async () => {
        try {
            // Collect values from the form items
            const formData = form.getFieldsValue();

            let formattedDataSource = [];

            const formattedDataSourceFinal = dataSource.reduce((acc, item) => {
                console.log(item);

                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.quantity || 0);
                const vatPercentage = parseFloat(
                    item.tax_perc > 0 ? item.tax_perc : 0 || 0,
                );
                const discount = parseFloat(item.discount || 0);
                const grossAmount = salePrice * quantity;
                const totalTaxableAmount = grossAmount - discount;
                const vatAmount = totalTaxableAmount * vatPercentage;
                const netAmount = totalTaxableAmount + vatAmount;
                const discountPercentage = discount / grossAmount;

                const formattedItem = {
                    ...item,
                    product: item.product.id,
                    product_name: item.product.name,
                    product_code: item.product.code,
                    multipack_id: sales_code
                        ? item.multipack.id
                        : item.multipack[0]?.id,
                    multipack_code: sales_code
                        ? item.multipack.code
                        : item.multipack[0]?.code,
                    multipack_name: sales_code
                        ? item.multipack.name
                        : item.multipack[0]?.name,
                    base_multipack_name: sales_code
                        ? item.multipack.base_multipack_name
                        : item.multipack[0]?.base_multipack_name,
                    base_multipack_id: sales_code
                        ? item.multipack.base_multipack_id
                        : item.multipack[0]?.base_multipack_id,
                    base_multipack_code: sales_code
                        ? item.multipack.base_multipack_code
                        : item.multipack[0]?.base_multipack_code,
                    multiplier: item.multipack[0]?.multiplier,
                    tax_total: roundNumber(
                        vatAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    gross_amount: roundNumber(
                        grossAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    net_amount: roundNumber(
                        netAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    total_taxable_amount: roundNumber(
                        totalTaxableAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    discount_perc: roundNumber(
                        discountPercentage,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                };

                if (amendSameProduct) {
                    const key = `${formattedItem.product}_${formattedItem.multipack_id}_${formattedItem.sales_type}`;

                    if (acc[key]) {
                        // If an item with the same key exists, update its quantities and amounts
                        acc[key].quantity += formattedItem.quantity;
                        acc[key].gross_amount += formattedItem.gross_amount;
                        acc[key].net_amount += formattedItem.net_amount;
                        acc[key].total_taxable_amount +=
                            formattedItem.total_taxable_amount;
                        acc[key].tax_total += formattedItem.tax_total;
                        acc[key].discount += formattedItem.discount;

                        // Recalculate discount percentage
                        acc[key].discount_perc = roundNumber(
                            acc[key].discount / acc[key].gross_amount,
                            roundingRule,
                            roundingPrecision + 1,
                        );
                    } else {
                        // If no item with the same key exists, add the new item to the accumulator
                        acc[key] = formattedItem;
                    }
                } else {
                    // If amendProduct is false, just add the item to the accumulator without combining
                    acc[formattedItem.id] = formattedItem;
                }

                return acc;
            }, {});

            // Convert the object back to an array
            formattedDataSource = Object.values(formattedDataSourceFinal);
            const totalTaxable = formattedDataSource.reduce((total, item) => {
                const taxableAmount = parseFloat(
                    item.total_taxable_amount || 0,
                );

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + taxableAmount;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - taxableAmount;
                }

                return total;
            }, 0);

            const grossAmount = formattedDataSource.reduce((total, item) => {
                const grossAmountValue = parseFloat(item.gross_amount || 0);

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + grossAmountValue;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - grossAmountValue;
                }

                return total;
            }, 0);

            const totalTax = formattedDataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.quantity || 0);
                const discount = parseFloat(item.discount || 0);
                const tax = parseFloat(item.tax_perc || 0);
                const gross = salePrice * quantity;
                const totalTaxable = gross - discount;
                const vat = totalTaxable * tax;

                const roundedVat = roundNumber(
                    vat,
                    roundingRule,
                    roundingPrecision + 1,
                );

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + roundedVat;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - roundedVat;
                }

                return total;
            }, 0);

            const totalDiscount = formattedDataSource.reduce((total, item) => {
                const discount = parseFloat(item.discount || 0);

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + discount;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - discount;
                }

                return total;
            }, 0);

            const totalNetAmount = formattedDataSource.reduce((total, item) => {
                const netAmount = parseFloat(item.net_amount || 0);

                if (item.salesType === 'sales' || item.salesType === 'foc') {
                    return total + netAmount;
                } else if (
                    item.salesType === 'gr' ||
                    item.salesType === 'expiry' ||
                    item.salesType === 'damage'
                ) {
                    return total - netAmount;
                }

                return total;
            }, 0);

            const salesTypeStats = formattedDataSource.reduce((stats, item) => {
                const salesType = item.sales_type;
                const quantity = parseInt(item.quantity || 0);
                const salePrice = parseFloat(item.salePrice || 0);
                const gross = salePrice * quantity;
                const netAmount = parseFloat(item.net_amount || 0);
                stats[salesType] = stats[salesType] || {
                    count: 0,
                    gross: 0,
                    net: 0,
                };
                stats[salesType].count++;
                stats[salesType].gross += gross;
                stats[salesType].net += netAmount;
                return stats;
            }, {});

            const formattedDate = moment(formData.date).format('YYYY-MM-DD');
            const currentTime = moment().format('HH:mm:ss');
            const endTime = moment().unix(); // Use .unix() to get the timestamp
            const duration = endTime - startTime;
            const formattedStartTime = moment
                .unix(startTime)
                .format('YYYY-MM-DD HH:mm:ss');
            const formattedEndTime = moment
                .unix(endTime)
                .format('YYYY-MM-DD HH:mm:ss');

            // Convert times to UTC before sending them
            let requestData = {
                invoice_no: draftSummary?.[0]?.invoice_no || text.toString(),
                transaction_started_time: convertLocalToUTC(
                    formattedStartTime,
                    'YYYY-MM-DD HH:mm:ss',
                    'time',
                ),
                transaction_ended_time: convertLocalToUTC(
                    formattedEndTime,
                    'YYYY-MM-DD HH:mm:ss',
                    'time',
                ),
                sales_date: convertLocalToUTC(
                    formattedDate,
                    'YYYY-MM-DD',
                    'date',
                ),
                customer: customer?.id || draftSummary?.[0]?.customer,
                customer_code:
                    customer?.code || draftSummary?.[0]?.customer_code,
                customer_name:
                    customer?.name || draftSummary?.[0]?.customer_name,
                customer_ho_id:
                    customer?.ho_id || draftSummary?.[0]?.customer_ho_id,
                customer_ho_name:
                    customer?.ho_name || draftSummary?.[0]?.customer_ho_name,
                customer_ho_code:
                    customer?.ho_code || draftSummary?.[0]?.customer_ho_code,
                customer_area_id:
                    customer?.area_id || draftSummary?.[0]?.customer_area_id,
                customer_area_name:
                    customer?.area_name ||
                    draftSummary?.[0]?.customer_area_name,
                customer_area_code:
                    customer?.area_code ||
                    draftSummary?.[0]?.customer_area_code,
                customer_region_id:
                    customer?.region_id ||
                    draftSummary?.[0]?.customer_region_id,
                customer_region_name:
                    customer?.region_name ||
                    draftSummary?.[0]?.customer_region_name,
                customer_region_code:
                    customer?.region_code ||
                    draftSummary?.[0]?.customer_region_code,
                customer_street: customer?.street || draftSummary?.[0]?.street,
                customer_building_number:
                    customer?.building_number ||
                    draftSummary?.[0]?.building_number,
                customer_city: customer?.city || draftSummary?.[0]?.city,
                customer_postal_code:
                    customer?.postal_code || draftSummary?.[0]?.postal_code,
                customer_district:
                    customer?.district || draftSummary?.[0]?.district,

                route: route || draftSummary?.[0]?.route,
                route_code: routeCode || draftSummary?.[0]?.route_code,
                route_name: routeName || draftSummary?.[0]?.route_name,
                route_manager_id:
                    selectedRoute?.route_manager_id ||
                    draftSummary?.[0]?.route_manager_id,
                route_manager_name:
                    selectedRoute?.route_manager_name ||
                    draftSummary?.[0]?.route_manager_name,
                route_manager_code:
                    selectedRoute?.route_manager_code ||
                    draftSummary?.[0]?.route_manager_code,
                ...(selectedSalesman && {
                    salesman:
                        selectedSalesman.id || draftSummary?.[0]?.salesman,
                    salesman_name:
                        selectedSalesman.name ||
                        draftSummary?.[0]?.salesman_name,
                    salesman_code:
                        selectedSalesman.code ||
                        draftSummary?.[0]?.salesman_code,
                }),

                gross_amount: grossAmount || draftSummary?.[0]?.gross_amount,
                remarks: remarks || draftSummary?.[0]?.remarks,
                currency_id: selectedCurrency || draftSummary?.[0]?.currency_id,
                currency: selectedCurrencyName || draftSummary?.[0]?.currency,
                currency_code:
                    selectedCurrencyCode || draftSummary?.[0]?.currency_code,
                status: 'printed',
                total_taxable_amount: roundNumber(
                    totalTaxable,
                    roundingRule,
                    roundingPrecision + 1,
                ),
                net_amount: roundNumber(
                    totalNetAmount,
                    roundingPrecision,
                    roundingRule,
                ),
                total_discount: roundNumber(
                    totalDiscount,
                    roundingPrecision,
                    roundingRule,
                ),
                tax_amount:
                    roundNumber(totalTax, roundingPrecision, roundingRule) ||
                    draftSummary?.[0]?.tax_amount,
                trans_type: transName || draftSummary?.[0]?.trans_type,
                sales_time: convertLocalToUTC(currentTime, 'HH:mm:ss', 'time'),
                transaction_duration: parseFloat(duration),
                paid: 0,
                balance: totalNetAmount,
                sales_details: formattedDataSource.map(item => ({
                    detail_code: item?.key?.toString(),
                    sales_type_id: item?.sales_type_id,
                    sales_type: item?.sales_type,
                    product: item?.product,
                    product_code: item?.product_code,
                    product_name: item?.product_name,
                    cost_price: item?.cost_price,
                    price_id: item?.price_id,
                    multipack_quantity: parseFloat(item?.quantity || 0),
                    min_sales: item?.min_sales,
                    max_discount: item?.max_discount,
                    group_id: item?.group_id,
                    group_name: item?.group_name,
                    group_code: item?.group_code,
                    tax_1: item?.tax_1,
                    tax_2: item?.tax_2,
                    tax_perc: item?.tax_perc,
                    tax_amount: item?.tax_total,
                    product_price: item?.product_price,
                    discount_amount: item?.discount || 0,
                    gross_amount: item?.gross_amount,
                    multipack_id: item?.multipack_id,
                    multipack_code: item?.multipack_code,
                    multipack_name: item?.multipack_name,
                    base_multipack_id: item?.base_multipack_id,
                    base_multipack_code: item?.base_multipack_code,
                    base_multipack_name: item?.base_multipack_name,
                    multiplier: item?.multiplier,
                    discount_perc: item?.discount_perc,
                    sale_price: item?.salePrice,
                    net_amount: item?.net_amount,
                    total_taxable_amount: item?.total_taxable_amount,
                    detail_reference: item?.detail_code,
                })),
                total_sales_count: salesTypeStats.sales?.count || 0,
                total_sales: salesTypeStats.sales?.gross || 0,
                total_sales_net: salesTypeStats.sales?.net || 0,
                total_foc_count: salesTypeStats.foc?.count || 0,
                total_foc: salesTypeStats.foc?.gross || 0,
                total_gr_count: salesTypeStats.gr?.count || 0,
                total_gr_net: salesTypeStats.gr?.net || 0,
                total_gr: salesTypeStats.gr?.gross || 0,
                total_expiry_count: salesTypeStats.expiry?.count || 0,
                total_expiry: salesTypeStats.expiry?.gross || 0,
                total_damage_count: salesTypeStats.damage?.count || 0,
                total_damage: salesTypeStats.damage?.gross || 0,
                billing_reference: billingReference || null,
            };

            if (grSelected) {
                if (!partialInvoice && grQuantityValidation) {
                    requestData.return_status = 'completely';
                } else if (partialInvoice && !grQuantityValidation) {
                    requestData.return_status = 'partially';
                }
            }

            // Check if the transaction type is "cash"
            if (transName === 'cash') {
                // If multi-sales type is allowed or sales type is "gr"
                if (multiSalesTypeAllowed || selectedSalesType?.name === 'gr') {
                    // Show collection modal if totalNetAmount is positive
                    if (totalNetAmount > 0) {
                        setCollectionModalVisible(true);
                        setTotalBalance(totalNetAmount);
                        setInitialTotalBalance(totalNetAmount);

                        // Store requestData for use in onFix
                        setRequestData(requestData);

                        // Set default values for the collection form
                        collectionForm.setFieldsValue({
                            collection: [
                                {
                                    paymentType: '',
                                    amount: totalNetAmount,
                                },
                            ],
                        });
                    } else {
                        // Do not show the collection modal if totalNetAmount is less than or equal to 0
                        collectionForm.resetFields();
                        confirmSales(requestData);
                    }
                } else {
                    // For sales types other than "gr" or if multiSalesTypeAllowed is not true
                    setCollectionModalVisible(true);
                    setTotalBalance(totalNetAmount);
                    setInitialTotalBalance(totalNetAmount);

                    // Store requestData for use in onFix
                    setRequestData(requestData);

                    // Set default values for the collection form
                    collectionForm.setFieldsValue({
                        collection: [
                            {
                                paymentType: '',
                                amount: totalNetAmount,
                            },
                        ],
                    });
                }
            } else {
                // For non-cash transactions, directly confirm sales
                console.log('requestData', requestData);

                confirmSales(requestData);
            }
        } catch (error) {
            message.error({
                content: 'An error occurred while submitting data',
                key: 'form',
            });
        }
    };

    // confirm Sales API Function

    const confirmSales = async requestData => {
        const response = await postApiWithAuthToken(
            '/api/v1/admin/salesSummary/confirmSales',
            requestData,
        );

        if (!response.error) {
            message.success({content: response.data, key: 'form'});
            setConfirmFinal(true);
            const latest = {
                ...requestData,
                inv: response.data?.invoice_no,
            };
            setInvoiceData(latest);
        } else {
            message.error({
                content: 'Failed to submit data',
                key: 'form',
            });
        }
    };

    //customer Details
    //get customer list and route
    function getCustomers() {
        getApiWithAuthToken('/api/v1/admin/customers').then(res => {
            if (res.data?.customers?.length > 0) {
                setCustomerDet(res.data?.customers);
                setCustData(false);
            } else {
                setCustomerDet([]);
            }
        });
    }
    //getRoutes
    function getRoute() {
        getApiWithAuthToken('/api/v1/admin/routes/transactionAllowed').then(
            res => {
                if (res.data?.length > 0) {
                    setRouteDet(res.data);
                } else {
                    setRouteDet([]);
                }
            },
        );
    }

    const getSalesTypes = async () => {
        try {
            const response = await getApiWithAuthToken(
                '/api/v1/admin/salesTypes',
            );
            if (!response.error && response.data?.length > 0) {
                setSalesType(response.data);
            } else {
            }
        } catch (error) {}
    };

    useEffect(() => {
        getCustomers();
        getRoute();
        getCurrency();
        getCompanyDetails();
        getSalesTypes();
    }, []);

    //saleSummary confirm

    const onFinish = values => {
        const formattedDate = moment(values.date).format('YYYY-MM-DD');

        const submissionValues = {
            ...values,
            sales_date: formattedDate,
            invoice_no: text.toString(),

            currency_id: currencyId,
            currency: currency,
            vat_reg_no: vatRegNo,
            customer: customer.id,
            customer_code: customer.code,
            customer_name: customer.name,
            customer_street: customer.street,
            customer_building_number: customer.building_number,
            customer_city: customer.city,
            customer_postal_code: customer.postal_code,
            customer_district: customer.district,

            route: route,
            route_code: routeCode,
            route_name: routeName,
            ...(selectedSalesman && {
                salesman: selectedSalesman.id,
                salesman_name: selectedSalesman.name,
                salesman_code: selectedSalesman.code,
            }),
            currency_id: selectedCurrency,
            currency: selectedCurrencyName,
            currency_code: selectedCurrencyCode,
            trans_type: transName,
            remarks: remarks,
        };

        postApiWithAuthToken(
            `/api/v1/admin/salesSummary`,
            submissionValues,
        ).then(res => {
            if (!res.error) {
                // message.success({content: res.data, key: 'form'});
                setConfirm(true);
            } else {
                if (res.error) {
                    message.error({content: res.error, key: 'form'});
                }
            }
        });
    };

    // Edit function

    //Add Sales items
    const EditableContext = React.createContext(null);
    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false} onFinish={FinalSubmit}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        handleRemoveRow,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const [inputValue, setInputValue] = useState(record?.[dataIndex] ?? ''); // State to store input value with default value if undefined
        const [minValue, setMinValue] = useState(record?.min_sales || 0);

        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing && inputRef.current) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            setEditing(!editing);
            setInputValue(record[dataIndex] ?? '');
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };
        const handleChange = (e, allowSpecialLogic = false) => {
            const {value} = e.target;
            if (allowSpecialLogic) {
                setInputValue(parseFloat(value));
            } else {
                setInputValue(Math.max(parseFloat(value), minValue));
            }
        };

        const handleBlur = () => {
            if (inputValue !== record[dataIndex]) {
                handleSave({
                    ...record,
                    [dataIndex]: inputValue,
                });
            }
            setEditing(false); // Always set editing to false on blur
        };
        const handleQuantityBlur = () => {
            if (inputValue !== record[dataIndex]) {
                if (routeEInvoiceEnable && grSelected) {
                    if (record.pending_quantity < inputValue) {
                        handleSave({
                            ...record,
                            quantity: record.pending_quantity,
                        });
                        return;
                    } else {
                        handleSave({
                            ...record,
                            quantity: inputValue,
                        });
                    }
                    setGrQuantityValidation(false);
                    setPartialInvoice(true);

                    if (record.pending_quantity === parseInt(inputValue)) {
                        setGrQuantityValidation(true);
                        setPartialInvoice(false);
                    }
                } else {
                    // Call handleQuantityChange to recalculate the price
                    handleQuantityChange(
                        record.key,
                        inputValue,
                        record.salesType,
                    );
                }
            }

            setEditing(false);
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                if (routeEInvoiceEnable && dataIndex === 'quantity') {
                    if (record.pending_quantity < values.quantity) {
                        handleSave({
                            ...record,
                            ...values,
                            quantity: record.pending_quantity,
                        });
                        return;
                    } else {
                        handleSave({
                            ...record,
                            ...values,
                        });
                    }
                    setGrQuantityValidation(false);
                    setPartialInvoice(true);

                    // Call handleQuantityChange to recalculate the price
                    handleQuantityChange(
                        record.key,
                        inputValue,
                        record.salesType,
                    );

                    if (record.pending_quantity === parseInt(values.quantity)) {
                        setGrQuantityValidation(true);
                        setPartialInvoice(false);
                    }
                } else if (dataIndex === 'quantity') {
                    handleSave({
                        ...record,
                        ...values,
                    });

                    // Call handleQuantityChange to recalculate the price
                    handleQuantityChange(
                        record.key,
                        inputValue,
                        record.salesType,
                    );
                } else {
                    handleSave({
                        ...record,
                        ...values,
                    });
                }
            } catch (errInfo) {}
        };

        let childNode = children;

        if (editable && dataIndex === 'quantity') {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                        maxWidth: 100,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Input
                        type='number'
                        style={{textAlign: 'right'}}
                        ref={inputRef}
                        onPressEnter={save}
                        max={
                            routeEInvoiceEnable ? record.pending_quantity : null
                        }
                        min={0}
                        onBlur={handleQuantityBlur}
                        onChange={e => handleChange(e, allowDecimal)}
                    />
                </Form.Item>
            ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{
                        paddingRight: 24,
                        height: '30px',
                        padding: '4px 11px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        textAlign: 'right',
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        } else if (editable && dataIndex === 'salePrice') {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                        maxWidth: 200,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const minValue = record['min_sales']; // Access minSalesPrice from the record object

                                if (
                                    !isNaN(minValue) &&
                                    !isNaN(value) &&
                                    value >= minValue
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        `The ${title} must be at least ${minValue}.`,
                                    ),
                                );
                            },
                        }),
                    ]}
                    initialValue={record[dataIndex]} // Set initial value
                >
                    <Input
                        type='number'
                        ref={inputRef}
                        onPressEnter={toggleEdit}
                        onBlur={handleBlur}
                        value={inputValue}
                        onChange={handleChange}
                        style={{textAlign: 'right'}}
                    />
                </Form.Item>
            ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{
                        paddingRight: 24,
                        height: '30px',
                        padding: '4px 11px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        textAlign: 'right',
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        } else if (editable && dataIndex === 'action') {
            childNode = (
                <Button type='link' danger>
                    Remove
                </Button>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    //function to handle selection of SalesType
    const handleSalesTypeChange = (recordKey, value, index) => {
        const selectedSalesType = salesType.find(type => type.name === value);

        // Lock the sales type if a new row is added and criteria are met
        if (dataSource.length > 1 && !lockedSalesType) {
            setLockedSalesType(value);
        }

        const updatedDataSource = dataSource.map(item => {
            if (item.key === recordKey) {
                return {
                    ...item,
                    salesType: value,
                    sales_type_id: selectedSalesType
                        ? selectedSalesType.id
                        : null,
                    sales_type: selectedSalesType
                        ? selectedSalesType.name
                        : null,
                };
            }
            return item;
        });

        setDataSource(updatedDataSource);
    };

    // JSX for rendering select input in table
    const renderSalesTypeSelect = (record, index) => {
        // Lock sales type for draft items and when multiple rows exist
        const isLocked =
            (record.fromApi && !!record.sales_type) ||
            (dataSource.length > 1 && !!lockedSalesType); // Lock when multiple rows and lockedSalesType is set

        const tooltipMessage = isLocked
            ? 'Sales type is locked because it is already selected or multiple sales types are not allowed'
            : null;

        return (
            <Tooltip title={tooltipMessage}>
                <Select
                    value={record.salesType}
                    onChange={value =>
                        handleSalesTypeChange(record.key, value, index)
                    }
                    style={{width: '100px'}}
                    allowClear={!isLocked} // Disable clear icon when locked
                    disabled={isLocked} // Disable selection if sales type is locked
                    placeholder={
                        isLocked ? tooltipMessage : 'Select Sales Type'
                    }
                >
                    {salesType.map(type => (
                        <Select.Option key={type.id} value={type.name}>
                            {type.name}
                        </Select.Option>
                    ))}
                </Select>
            </Tooltip>
        );
    };
    //Edit table components

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    //state for discount editable cells

    const [editingDiscount, setEditingDiscount] = useState(true);
    const [totalDiscounValue, setTotalDiscountValue] = useState(0);
    //productlist modal state
    const [productModal, setProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    //multipack list state
    const [selectedMultipackValues, setSelectedMultipackValues] = useState({});
    const [grMultipackValues, setGrMultipackValues] = useState({});
    const [priceDataResponse, setPriceDataResponse] = useState([]);
    const [allowDecimal, setAllowDecimal] = useState(false);

    const [currentlyEditingKey, setCurrentlyEditingKey] = useState(null);
    const inputRef = useRef(null); // Define a ref for the input field

    // Focus on the input field when the modal is opened
    useEffect(() => {
        if (productModal) {
            inputRef.current.focus(); // Focus on the input field
        }
    }, [productModal]);

    //productlist modal view

    const handleProductModal = (record, rowIndex) => {
        setSelectedProduct({...record, rowIndex});
        setProductModal(true);
    };

    const handleEditDiscount = async (key, value, max_discount) => {
        // Handle empty string case when clearing the input
        if (value === '') {
            value = ''; // Keep it empty instead of converting to 0
        } else {
            // Convert value to a number if it's not empty
            const numValue = parseFloat(value);

            // Only update if it's a valid number
            if (!isNaN(numValue)) {
                value = numValue;
            } else {
                return; // Exit if invalid number
            }
        }

        const isExceedingMax = max_discount && value > max_discount;
        const tooltipValue = isExceedingMax ? max_discount : value;

        // Create a copy of the dataSource
        const newData = dataSource.map(item => {
            if (item.key === key) {
                return {...item, discount: tooltipValue};
            }
            return item;
        });

        // Calculate total discount value (skip empty values)
        const totalDiscountValue = newData.reduce(
            (total, item) =>
                total + (item.discount === '' ? 0 : Number(item.discount)),
            0,
        );

        setTotalDiscountValue(totalDiscountValue);
        setCurrentlyEditingKey(key);

        // Check for max discount violations
        const exceedsMaxDiscount = newData.some(
            item => item.discount > (item.max_discount || Infinity),
        );

        if (exceedsMaxDiscount) {
            setTotalDiscountValue(0);
            message.error('Discount exceeds maximum allowed value');
        } else {
            setDataSource(newData);
        }

        // Rest of your API call code remains the same
        try {
            const payload = newData.map(item => {
                const {
                    multipack,
                    salesType,
                    salePrice,
                    quantity,
                    transactionType,
                    total,
                    vat,
                    ...payloadItem
                } = item;
                return {
                    ...payloadItem,
                    sales_code: text.toString(),
                    detail_code: item.key.toString(),
                    product: item.product.id,
                    product_name: item.product.name,
                    product_code: item.product.code,
                    multipack_quantity: parseInt(item.quantity),
                    gross_amount: item.salePrice * item.quantity,
                    total_taxable_amount: roundNumber(
                        item.salePrice * item.quantity - (item.discount || 0),
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    tax_amount: roundNumber(
                        (item.salePrice * item.quantity -
                            (item.discount || 0)) *
                            item.tax_perc,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    multipack_id: item.multipack[0].id,
                    multipack_code: item.multipack[0].code,
                    multipack_name: item.multipack[0].name,
                    base_multipack_id: item.multipack[0].base_multipack_id,
                    base_multipack_code: item.multipack[0].base_multipack_code,
                    base_multipack_name: item.multipack[0].base_multipack_name,
                    multiplier: item.multipack[0].multiplier,
                    discount_perc: item.discountPercentage,
                    discount_amount: item.discount ? item.discount : 0,
                    sale_price: item.salePrice,
                };
            });

            const response = await postApiWithAuthToken(
                '/api/v1/admin/salesDetails/draft',
                {
                    sales_details: payload,
                },
            );
        } catch (error) {
            // Handle error if needed
        }
    };
    const handleProductModalclose = () => {
        setProductModal(false);
        setSearchInput('');
        setFilterOption('name');
    };
    const handleProductSelect = async productId => {
        const selected = productList.find(product => product.id === productId);
        setSelectProductId(selected.id);
        const tax1 = typeof selected.tax_1 === 'number' ? selected.tax_1 : 0;
        const tax2 = typeof selected.tax_2 === 'number' ? selected.tax_2 : 0;
        const totalTax = tax1 + tax2;

        // Make API call to fetch multipack options
        try {
            const response = await getApiWithAuthToken(
                `/api/v1/admin/productMultipacks/price?product_id=${productId}&priceExist=1`,
            );
            if (response.data) {
                const multipackOptions = response.data.map(multipack => ({
                    id: multipack.id,
                    code: multipack.code,
                    name: multipack.name,
                    base_multipack_id: multipack.base_multipack_id,
                    base_multipack_code: multipack.base_multipack_code,
                    base_multipack_name: multipack.base_multipack_name,
                    multiplier: multipack.multiplier,
                }));

                const updatedDataSource = dataSource.map(item => {
                    if (item.key === selectedProduct.rowIndex) {
                        return {
                            ...item,
                            product: selected,
                            discount: 0,
                            tax_1: tax1,
                            tax_2: tax2,
                            tax_perc: totalTax,
                            multipack: multipackOptions, // Save multipack options in the item
                        };
                    }
                    return item;
                });

                setDataSource(updatedDataSource);
                setSearchInput('');
            }
        } catch (error) {
            message.error('Failed to fetch multipack options');
        }

        setSelectedProduct(null);
        setProductModal(false);
        setFilterOption('name');
    };

    // Inside setEditingDiscount function
    const toggleEditingDiscount = value => {
        // Reset total discount value and discount fields when turning off editing mode
        if (!value) {
            const resetDiscountData = dataSource.map(item => {
                return {...item, discount: 0};
            });
            setDataSource(resetDiscountData);
            setTotalDiscountValue(0);
        }
        setEditingDiscount(value);
    };

    const customerField = (
        <Form.Item
            name='customer'
            label={
                <span>
                    Customer <span style={{color: 'red'}}>*</span>
                </span>
            }
            rules={[
                {
                    required: true,
                    message: 'Please select a customer',
                },
            ]}
            style={{
                backgroundColor: '#f9faff',
                padding: '8px',
                borderRadius: '6px',
                marginBottom: '16px',
            }}
        >
            {sales_code ? (
                draftSummary[0]?.customer_name
            ) : (
                <Select
                    style={{width: '200px'}}
                    loading={custData}
                    showSearch
                    disabled={confirm}
                    placeholder='Select Customer'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={customerId => {
                        const newStartTime = moment().format('X');
                        setStartTime(newStartTime);
                        const selectedCustomer = customerDet.find(
                            customer => customer.id === customerId,
                        );
                        if (selectedCustomer) {
                            setCustomer(selectedCustomer);
                        }
                    }}
                >
                    {customerDet.map(customer => (
                        <Select.Option key={customer.id} value={customer.id}>
                            {customer.name}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Form.Item>
    );
    // Function to handle route selection
    const handleRouteSelect = routeId => {
        if (routeDet) {
            const selectedRoute = routeDet.find(route => route.id === routeId);
            if (selectedRoute && selectedRoute.available === true) {
                setRoute(routeId);
                setRouteName(selectedRoute.name);
                setRouteCode(selectedRoute.code);
                setSelectedRoute(selectedRoute);
                setRouteEInvoiceEnable(selectedRoute?.e_invoice_enabled);
                setSalesman([
                    {
                        id: selectedRoute.salesman_id,
                        name: selectedRoute.salesman,
                        code: selectedRoute.salesman_code,
                    },
                ]);
                form.setFieldsValue({
                    salesman_id: null, // Reset salesman select box
                });
            } else {
                // If the selected route is not active, you may want to reset the form or show a message
                message.error('The selected route is not active.');
                form.resetFields(['route_id']);
            }
        }
    };

    const transTypeField = (
        <Form.Item
            name='trans_type'
            label={
                <span>
                    Trans Type <span style={{color: 'red'}}>*</span>
                </span>
            }
            rules={[
                {
                    required: true,
                    message: 'Please select a Trans Type',
                },
            ]}
            style={{
                backgroundColor: '#f9faff',
                padding: '8px',
                borderRadius: '6px',
                marginBottom: '16px',
            }}
        >
            {draftSummary[0]?.trans_type ? (
                <Tooltip
                    title='Transaction type is pre-filled from draft'
                    color='geekblue'
                >
                    <span>{draftSummary[0]?.trans_type}</span>
                </Tooltip>
            ) : (
                <Select
                    style={{width: '200px'}}
                    disabled={confirm || !customer} // Disable if confirmed or no customer selected
                    placeholder='Select Transaction Type'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    value={transName}
                    onSelect={trans => {
                        setTransName(trans);
                    }}
                >
                    {transType?.split(',').map((type, index) => (
                        <Select.Option key={index} value={type.trim()}>
                            {type.trim()}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Form.Item>
    );

    //check for priceType and price

    const handleMultipackChange = async (recordKey, value, salesType) => {
        setSelectedMultipackValues(prevState => ({
            ...prevState,
            [recordKey]: value,
        }));

        try {
            const res = await getApiWithAuthToken(
                `/api/v1/admin/priceKeyGroups/price/${productId}/${customer.id}/${route}/${value}`,
            );

            if (!res.error && res.data && res.data.length > 0) {
                setPriceDataResponse(res.data);
            } else {
            }
        } catch (error) {
            // Handle API call error
        }
    };

    const handleQuantityChange = (recordKey, quantity, salesType) => {
        if (routeEInvoiceEnable && grSelected) {
            // console.log('working');
        } else {
            const matchingItems = priceDataResponse.filter(item => {
                return (
                    quantity >= item.from_quantity &&
                    quantity <= item.to_quantity
                );
            });

            if (matchingItems.length > 0) {
                let selectedPriceData;

                if (matchingItems.length === 1) {
                    selectedPriceData = matchingItems[0];
                } else {
                    // If multiple matching items, sort them by priority
                    const sortedItems = matchingItems.sort(
                        (a, b) => a.priority - b.priority,
                    );
                    selectedPriceData = sortedItems[0];
                }

                // Set allowDecimal based on the selectedPriceData
                const allowDecimalValue =
                    selectedPriceData?.allow_decimal_quantity ?? false;
                setAllowDecimal(allowDecimalValue);

                // Set the selected price based on salesType
                let selectedPrice = 0;
                switch (salesType) {
                    case 'sales':
                        selectedPrice = selectedPriceData.sales;
                        break;
                    case 'foc':
                        selectedPrice = selectedPriceData.foc;
                        break;
                    case 'gr':
                        selectedPrice = selectedPriceData.gr;
                        break;
                    case 'expiry':
                        selectedPrice = selectedPriceData.expiry;
                        break;
                    case 'damage':
                        selectedPrice = selectedPriceData.damage;
                        break;
                    default:
                        selectedPrice = 0;
                }

                // Update the dataSource with the selected price
                const updatedDataSource = dataSource.map(item => {
                    if (item.key === recordKey) {
                        return {
                            ...item,
                            salePrice: selectedPrice,
                            product_price: selectedPrice,
                            min_sales: selectedPriceData.min_sales,
                            max_discount:
                                selectedPriceData.max_discount || null,
                            salesType: salesType,
                            cost_price: selectedPriceData.cost_price,
                            price_id: selectedPriceData.id,
                            group_code: selectedPriceData.group_code,
                            group_id: selectedPriceData.group_id,
                            group_name: selectedPriceData.name,

                            quantity: quantity,
                        };
                    }
                    return item;
                });
                setDataSource(updatedDataSource);
            }
        }
    };

    //remove function of each row
    const handleRemoveRow = async key => {
        try {
            // Filter out the removed row from dataSource
            const updatedDataSource = dataSource.filter(
                item => item.key !== key,
            );
            setDataSource(updatedDataSource);

            // Reset lockedSalesType if there are no rows left in the data source
            if (updatedDataSource.length === 0) {
                setTotalDiscountValue(0);
                setLockedSalesType(null);
            }

            // Remove the corresponding entry from selectedMultipackValues
            setSelectedMultipackValues(prevState => {
                const newState = {...prevState};
                delete newState[key];
                return newState;
            });

            // setpartialInvoice(true);
            if (routeEInvoiceEnable && grSelected) {
                setPartialInvoice(true);
                setGrQuantityValidation(false);
            }

            // Wrap the payload in the desired format
            const payload = {
                sales_details: updatedDataSource.map(item => ({
                    detail_code: item.key.toString(),
                    sales_code: text.toString(),
                    product: item.product.id,
                    product_name: item.product.name,
                    product_code: item.product.code,
                    gross_amount: item.salePrice * item.quantity,
                    total_taxable_amount: roundNumber(
                        item.salePrice * item.quantity - item.discount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    tax_amount: roundNumber(
                        (item.salePrice * item.quantity - item.discount) *
                            item.tax_perc,
                        roundingRule,
                        roundingPrecision + 1,
                    ),

                    tax_1: item.tax_1,
                    tax_2: item.tax_2,
                    multipack_id: item.multipack.id,
                    multipack_code: item.multipack.code,
                    multipack_name: item.multipack.name,
                    base_multipack_id: item.multipack.base_multipack_id,
                    base_multipack_code: item.multipack.base_multipack_code,
                    base_multipack_name: item.multipack.base_multipack_name,
                    multiplier: item.multipack.multiplier,
                    discount_perc: item.discount_perc,
                    discount_amount: item.discount ? item.discount : 0,
                })),
            };

            // Remove multipack object from the payload
            // payload.sales_details.forEach(item => delete item.multipack);
            payload.sales_details.forEach(item => delete item.salesType);
            payload.sales_details.forEach(item => delete item.tax_perc);
            payload.sales_details.forEach(item => delete item.transactionType);

            // Make the API call
            const response = await postApiWithAuthToken(
                '/api/v1/admin/salesDetails/draft',
                payload,
            );

            if (response && response.data) {
                message.success(response.data);
            } else {
            }
        } catch (error) {}
    };

    //rounding numbers

    function roundNumber(value, roundingRule, roundingPrecision) {
        if (roundingRule === 'round') {
            return (
                Math.round(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else if (roundingRule === 'ceil') {
            return (
                Math.ceil(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else if (roundingRule === 'floor') {
            return (
                Math.floor(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else {
            // Default to rounding to 2
            return Math.round(value * 100) / 100;
        }
    }

    //add form table columns

    const formColumns = [
        {
            title: 'Sales Type',
            dataIndex: 'sales_type',
            render: (text, record, index) =>
                record.fromApi ? (
                    <span>{record.sales_type}</span>
                ) : (
                    renderSalesTypeSelect(record, index)
                ),
        },

        {
            title: 'Product',
            dataIndex: 'product',
            width: '15%',
            editable: true,
            render: (_, record) => {
                return record.fromApi ? (
                    <span>{record.product ? record.product.name : ''}</span>
                ) : (
                    <span>
                        {grSelected ? (
                            record.product ? (
                                record.product.name
                            ) : (
                                ''
                            )
                        ) : (
                            <span
                                onClick={() =>
                                    handleProductModal(record, record.key)
                                }
                            >
                                {record.product ? (
                                    record.product.name
                                ) : (
                                    <Button>Select Product</Button>
                                )}
                            </span>
                        )}
                    </span>
                );
            },
        },

        {
            title: 'Multipack',
            dataIndex: 'multipack',
            editable: true,
            width: '10%',
            render: (_, record) => {
                const rowIndex = dataSource.findIndex(
                    item => item.key === record.key,
                );

                return record.fromApi ? (
                    <span>{record.multipack ? record.multipack.name : ''}</span>
                ) : grMultipackValues[0] ? (
                    <Input
                        readOnly
                        value={grMultipackValues[0]?.name}
                        style={{width: '100%'}}
                    />
                ) : (
                    <Select
                        disabled={!record.product}
                        value={selectedMultipackValues[rowIndex]} // Set the selected value based on the row key
                        onChange={value =>
                            handleMultipackChange(
                                record.key,
                                value,
                                record.salesType,
                            )
                        }
                        style={{width: '100%'}}
                    >
                        {multipackList.map(multipack => (
                            <Select.Option
                                key={multipack.id}
                                value={multipack.id}
                            >
                                {multipackNames[multipack.id] || multipack.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '10%',
            editable: true,
        },
        {
            title: 'Price',
            dataIndex: 'salePrice',
            editable: !grSelected,
            width: '10%',
        },
        {
            title: 'Gross Amount',
            dataIndex: 'gross_amount',
            width: '13%',
            render: (_, record) => {
                const {salePrice, quantity} = record;
                //net Amount Calculation
                const Gross = salePrice * quantity;

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {roundNumber(Gross, roundingRule, roundingPrecision)}
                    </span>
                );
            },
        },
        {
            title: !grSelected ? (
                <span>
                    Discount
                    <Button
                        type='link'
                        size='small'
                        onClick={() => toggleEditingDiscount(!editingDiscount)}
                    >
                        {editingDiscount ? 'Reset' : 'Edit'}
                    </Button>
                </span>
            ) : (
                'Discount'
            ),
            dataIndex: 'discount',
            editable: editingDiscount,
            width: '10%',
            render: (_, record) => {
                const {key, discount, max_discount, product} = record;
                const totalItems = dataSource.length;
                let updatedDiscount = discount; // Start with the saved discount value

                // Only calculate divided discount if a product is selected
                if (product && totalDiscounValue) {
                    // Divide total discount by the number of items
                    let dividedDiscount = totalDiscounValue / totalItems;

                    // Ensure dividedDiscount doesn't exceed max_discount
                    if (max_discount && dividedDiscount > max_discount) {
                        dividedDiscount = max_discount;
                    }

                    // Use divided discount if editing is not enabled
                    updatedDiscount = editingDiscount
                        ? discount
                        : dividedDiscount;
                }

                // Format the discount for display
                const formattedDiscount = roundNumber(
                    updatedDiscount,
                    roundingPrecision,
                    roundingRule,
                );

                const inputComponent = (
                    <Input
                        value={
                            currentlyEditingKey === key
                                ? formattedDiscount
                                : formattedDiscount === '0'
                                  ? ''
                                  : formattedDiscount
                        }
                        onChange={e =>
                            handleEditDiscount(
                                key,
                                e.target.value,
                                max_discount,
                            )
                        }
                        ref={inputRef => {
                            if (inputRef && key === currentlyEditingKey) {
                                inputRef.focus();
                            }
                        }}
                        onFocus={e => {
                            if (e.target.value === '0') {
                                e.target.value = '';
                            }
                            setCurrentlyEditingKey(key);
                        }}
                        onBlur={() => setCurrentlyEditingKey(null)}
                        style={{textAlign: 'right'}}
                        disabled={!product || grSelected}
                    />
                );

                return editingDiscount ? (
                    <Tooltip
                        title={
                            max_discount
                                ? `Max discount is: ${max_discount}`
                                : null
                        }
                        trigger={['focus', 'hover']}
                        placement='topRight'
                    >
                        {inputComponent}
                    </Tooltip>
                ) : (
                    <span onClick={() => setCurrentlyEditingKey(key)}>
                        {formattedDiscount}
                    </span>
                );
            },
        },

        {
            title: 'Tot Tax Amt.',
            dataIndex: 'total_taxable_amt',
            width: '13%',
            render: (_, record) => {
                const {salePrice, quantity, discount} = record;
                // vat calculation here
                const total_taxable_amt = roundNumber(
                    salePrice * quantity - discount,
                    roundingRule,
                    roundingPrecision,
                );
                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {total_taxable_amt}
                    </span>
                );
            },
        },

        {
            title: 'Tax Amt.',
            dataIndex: 'vat',
            width: '10%',
            render: (_, record) => {
                const {
                    salePrice = 0,
                    quantity = 0,
                    tax_perc = 0,
                    discount = 0,
                } = record;
                const totalTaxable = salePrice * quantity - discount;
                // vat calculation here
                const vat = roundNumber(
                    totalTaxable * tax_perc,
                    roundingRule,
                    roundingPrecision,
                );

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {vat}
                    </span>
                );
            },
        },

        {
            title: 'Net Amount',
            dataIndex: 'total',
            width: '10%',
            render: (_, record) => {
                // Destructure with default values
                const {
                    tax_perc = 0,
                    salePrice = 0,
                    discount = 0,
                    quantity = 0,
                } = record;

                // Net Amount Calculation
                const gross = salePrice * quantity;
                const totalTaxable = gross - discount;
                const taxAmount = totalTaxable * tax_perc;
                const netAmount = totalTaxable + taxAmount;

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {roundNumber(
                            netAmount,
                            roundingRule,
                            roundingPrecision,
                        )}
                    </span>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'action',
            width: '5%',
            render: (_, record) => (
                <Button
                    type='link'
                    danger
                    onClick={() => handleRemoveRow(record.key)}
                >
                    Remove
                </Button>
            ),
        },
    ];

    const [dataSource, setDataSource] = useState([]);

    const [productList, setProductList] = useState([]);
    const [multipackList, setMultipackList] = useState([]);
    const [count, setCount] = useState(0);
    const [showTable, setShowTable] = useState(false);

    //product modal filter states
    const [searchInput, setSearchInput] = useState('');
    const [filterOption, setFilterOption] = useState('name');

    //multipack

    const [multipackNames, setMultipackNames] = useState({});

    //collection modal

    const [collectionForm] = Form.useForm();

    // States
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [paymentTypesCount, setPaymentTypesCount] = useState(0);
    const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
    const [transactionStartedTime, setTransactionStartedTime] = useState(null);
    const [totalBalance, setTotalBalance] = useState(0);
    const [initialTotalBalance, setInitialTotalBalance] = useState(0);
    const [totalAmounts, setTotalAmounts] = useState([]);

    useEffect(() => {
        if (collectionModalVisible) {
            setSelectedPaymentTypes([]);
            setTotalAmounts([]);
            setTotalBalance(initialTotalBalance);

            collectionForm.setFieldsValue({
                collection: [
                    {
                        paymentType: undefined,
                        amount: undefined,
                    },
                ],
            });
        }
    }, [collectionModalVisible, initialTotalBalance]);
    //fetch Products and multipacks
    useEffect(() => {
        fetchProductList();
        getPaymentTypes();
    }, []);
    useEffect(() => {
        fetchMultipackList();
    }, [productId]);

    function getPaymentTypes() {
        getApiWithAuthToken(
            '/api/v1/admin/paymentTypes?payment_type_group=collection_payment_type',
        ).then(res => {
            if (res.data?.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].active = true;
                }
                setPaymentTypesCount(res.data.length);
                setPaymentTypes(res.data);
            } else {
                setPaymentTypes([]);
            }
        });
    }

    const fetchProductList = async () => {
        try {
            const response = await getApiWithAuthToken(
                '/api/v1/admin/products/active?priceExist=1',
            );
            if (response.data) {
                setProductList(response.data);
            }
        } catch (error) {}
    };
    const fetchMultipackList = async () => {
        try {
            const response = await getApiWithAuthToken(
                `/api/v1/admin/productMultipacks/price?product_id=${productId}&priceExist=1`,
            );
            if (response.data) {
                const newMultipackNames = {};
                response.data.forEach(multipack => {
                    newMultipackNames[multipack.id] = multipack.name;
                });
                setMultipackNames(newMultipackNames);

                setMultipackList(response.data);
            }
        } catch (error) {}
    };

    const handleAdd = () => {
        const firstRowSalesType = dataSource[0]?.salesType || lockedSalesType;

        // Lock sales type when adding a new row
        if (dataSource.length > 0 && !lockedSalesType) {
            setLockedSalesType(firstRowSalesType);
        }

        const selectedSalesType = salesType.find(
            type => type.name === firstRowSalesType,
        );

        const newData = {
            key: dataSource.length.toString(),
            salesType: firstRowSalesType || '',
            sales_type_id: selectedSalesType ? selectedSalesType.id : null,
            sales_type: selectedSalesType ? selectedSalesType.name : null,
            product: '',
            multipack: '',
            quantity: '',
            salePrice: '',
            vat: '',
            discount: '',
            total: '',
        };

        setDataSource([...dataSource, newData]);
        setCount(count + 1);
        setShowTable(true);
        setEditable(true);
    };
    const resetTransaction = () => {
        setLockedSalesType(null);
        setDataSource([]);
        setShowTable(false);
        setEditable(false);
        setCount(0);
    };

    const resetGr = () => {
        setDataSource([...grSalesDetails]);
        setPartialInvoice(false);
        setGrQuantityValidation(true);
    };

    const handleSave = async updatedRow => {
        try {
            const newData = [...dataSource];
            const index = newData.findIndex(
                item => updatedRow.key === item.key,
            );
            const oldRow = newData[index];

            // Calculate vat and total based on updatedRow values

            const grossAmount = roundNumber(
                updatedRow.salePrice * updatedRow.quantity,
            );
            const total = roundNumber(
                grossAmount - updatedRow.discount,
                roundingPrecision,
                roundingRule,
            );
            const vat = roundNumber(
                total * updatedRow.tax_perc,
                roundingRule,
                roundingPrecision,
            );
            const Net = roundNumber(
                total + vat,
                roundingRule,
                roundingPrecision + 1,
            );

            // Calculate discount percentage
            const discountPercentage = roundNumber(
                updatedRow.discount / grossAmount,
                roundingPrecision,
                roundingRule,
            );

            // Include the quantity, salePrice, and gross amount fields in the updatedRow
            const updatedRowWithValues = {
                ...oldRow,
                ...updatedRow,
                vat_amt: vat,
                net_amount: Net,
                total_taxable_amount: total,
                gross_amount: grossAmount,

                discount_perc: discountPercentage,
            };

            newData.splice(index, 1, updatedRowWithValues);
            setDataSource(newData);

            // Wrap the payload in the desired format
            const payload = {
                sales_details: dataSource.map(item => {
                    const salesDetail = {
                        ...item,
                        sales_code: text.toString(),
                        detail_code: item.key.toString(),
                        product: item.product.id,
                        product_name: item.product.name,
                        product_code: item.product.code,
                        gross_amount: item.salePrice * item.quantity,
                        tax_amount: roundNumber(
                            item.salePrice * item.quantity * item.totalTax,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        tax_1: item.tax1,
                        tax_2: item.tax2,
                        multipack_id: item.multipack.id,
                        multipack_code: item.multipack[0].code,
                        multipack_name: item.multipack[0].name,
                        base_multipack_id: item.multipack[0].base_multipack_id,
                        base_multipack_code:
                            item.multipack[0].base_multipack_code,
                        base_multipack_name:
                            item.multipack[0].base_multipack_name,
                        multiplier: item.multipack[0].multiplier,
                        discount_perc: item.discount_perc,
                        discount_amount: item.discount ? item.discount : 0,
                    };

                    // Include multipack_quantity if present
                    if (item.quantity) {
                        salesDetail.multipack_quantity = parseFloat(
                            item.quantity,
                        );
                    }

                    // Include sale_price if present
                    if (item.salePrice) {
                        salesDetail.sale_price = parseInt(item.salePrice);
                    }

                    // Include discount_amount if present
                    if (item.discount) {
                        salesDetail.discount_amount = parseInt(item.discount);
                    }

                    return salesDetail;
                }),
            };

            // Remove multipack object from the payload
            payload.sales_details.forEach(item => delete item.multipack);
            payload.sales_details.forEach(item => delete item.salesType);
            payload.sales_details.forEach(item => delete item.transactionType);
            payload.sales_details.forEach(item => delete item.quantity);
            payload.sales_details.forEach(item => delete item.salePrice);
            payload.sales_details.forEach(item => delete item.discount);
            payload.sales_details.forEach(item => delete item.vat);
            payload.sales_details.forEach(item => delete item.tax_perc);
            payload.sales_details.forEach(item => delete item.total);
            payload.sales_details.forEach(item => delete item.key);

            // Make the API call
            const response = await postApiWithAuthToken(
                '/api/v1/admin/salesDetails/draft',
                payload,
            );

            if (response && response.data) {
                message.success(response.data);
            } else {
            }
        } catch (error) {}
    };

    const columnss = formColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave, // Pass the handleSave function to each editable column
            }),
        };
    });

    //collection add modal

    const handleSelectChange = (value, index) => {
        // Set transaction time if not already set
        if (!transactionStartedTime) {
            const now = new Date();
            const timeString = now.toLocaleTimeString('en-US', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            setTransactionStartedTime(timeString);
        }

        // Update selected payment types
        const newSelectedTypes = [...selectedPaymentTypes];
        newSelectedTypes[index] = value;
        setSelectedPaymentTypes(newSelectedTypes);
    };

    // Handle amount change in form fields
    const handleAmountChange = (value, index) => {
        const numValue = parseFloat(value) || 0;
        const newAmounts = [...totalAmounts];
        newAmounts[index] = numValue;

        // Calculate total excluding current field
        const otherAmountsTotal = newAmounts.reduce(
            (sum, amount, idx) =>
                idx !== index ? sum + (parseFloat(amount) || 0) : sum,
            0,
        );

        // Update balance
        const newBalance = initialTotalBalance - (otherAmountsTotal + numValue);
        setTotalBalance(Math.max(0, newBalance));
        setTotalAmounts(newAmounts);
    };

    // Handle removing payment rows
    const handleRemoveRows = (index, remove) => {
        // Update amounts
        const newAmounts = [...totalAmounts];
        newAmounts.splice(index, 1);
        setTotalAmounts(newAmounts);

        // Recalculate total
        const newTotal = newAmounts.reduce(
            (sum, amount) => sum + (parseFloat(amount) || 0),
            0,
        );
        const newBalance = initialTotalBalance - newTotal;
        setTotalBalance(Math.max(0, newBalance));

        // Update selected payment types
        setSelectedPaymentTypes(prev => prev.filter((_, i) => i !== index));

        // Remove row
        remove(index);
    };
    // collection modal submit
    const onFix = async values => {
        const {collection} = values;

        const formattedData = collection.map(item => ({
            payment_type_id: item.paymentType,
            payment_type_name: paymentTypes.find(
                type => type.id === item.paymentType,
            )?.name,
            paid: parseFloat(item.amount),
        }));

        const data = {
            ...requestData, // Include original request data from state
            payment_types: formattedData,

            balance: 0, // Set balance to 0 for cash transactions
            paid: requestData.net_amount, // Set paid to net_amount for cash transactions
        };

        try {
            const res = await postApiWithAuthToken(
                '/api/v1/admin/salesSummary/confirmSales',
                data,
            );

            if (res.status === 'ok') {
                message.success({content: res.data});
                setConfirmFinal(true);
                setCollectionFinal(true);
                const latest = {
                    ...data,
                    collection_code: res?.data?.collection_code,
                    inv: res.data?.invoice_no,
                };
                setInvoiceData(latest);
                setCollectionData(latest);
                // history.push('/sales-summary');
            }
        } catch (error) {}
    };

    //final Value calculation for table summary
    const summaryRow = () => {
        const calculateAmount = item => {
            const salesType = item.salesType || item.sales_type;
            const salePrice = parseFloat(item.salePrice || 0);
            const quantity = parseInt(
                item.quantity || item.multipack_quantity || 0,
            );
            const discount = parseFloat(
                item.discount || item.discount_amount || 0,
            );
            const taxPerc = parseFloat(
                item.tax_perc || item.tax_1 + item.tax_2 || 0,
            );

            // Calculate basic amounts
            const gross = salePrice * quantity;
            const taxable = gross - discount;
            const taxAmount = taxable * taxPerc;
            const netAmount = taxable + taxAmount;

            // Round all values consistently
            const amounts = {
                gross: roundNumber(gross, roundingRule, roundingPrecision + 1),
                taxable: roundNumber(
                    taxable,
                    roundingRule,
                    roundingPrecision + 1,
                ),
                tax: roundNumber(
                    taxAmount,
                    roundingRule,
                    roundingPrecision + 1,
                ),
                net: roundNumber(
                    netAmount,
                    roundingRule,
                    roundingPrecision + 1,
                ),
            };

            // Apply sales type multiplier (positive for sales/foc, negative for returns)
            const multiplier = ['sales', 'foc'].includes(salesType)
                ? 1
                : ['gr', 'damage', 'expiry'].includes(salesType)
                  ? -1
                  : 0;

            return {
                gross: amounts.gross * multiplier,
                taxable: amounts.taxable * multiplier,
                tax: amounts.tax * multiplier,
                net: amounts.net * multiplier,
            };
        };

        // Calculate totals
        const totals = dataSource.reduce(
            (acc, item) => {
                const amounts = calculateAmount(item);
                return {
                    totalSales: acc.totalSales + amounts.gross,
                    totalVat: acc.totalVat + amounts.taxable,
                    totalTax: acc.totalTax + amounts.tax,
                    totalNetAmount: acc.totalNetAmount + amounts.net,
                };
            },
            {
                totalSales: 0,
                totalVat: 0,
                totalTax: 0,
                totalNetAmount: 0,
            },
        );

        const handleTotalDiscountChange = async value => {
            const numValue = parseFloat(value);

            // Validate discount value
            if (isNaN(numValue) || numValue < 0) {
                setTotalDiscountValue(0);
                return;
            }

            // Calculate max possible discount
            const maxPossibleDiscount = dataSource.reduce(
                (total, item) => total + parseFloat(item.max_discount || 0),
                0,
            );

            // Clamp discount to maximum
            const finalDiscount = Math.min(numValue, maxPossibleDiscount);

            // Calculate per-item discount
            const itemCount = dataSource.length;
            const perItemDiscount =
                itemCount > 0 ? finalDiscount / itemCount : 0;

            // Update each item with new discount
            const updatedData = dataSource.map(item => {
                const itemDiscount = Math.min(
                    roundNumber(
                        perItemDiscount,
                        roundingPrecision,
                        roundingRule,
                    ),
                    parseFloat(item.max_discount || 0),
                );

                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(
                    item.quantity || item.multipack_quantity || 0,
                );
                const gross = roundNumber(
                    salePrice * quantity,
                    roundingRule,
                    roundingPrecision + 1,
                );
                const taxable = roundNumber(
                    gross - itemDiscount,
                    roundingRule,
                    roundingPrecision + 1,
                );
                const taxPerc = parseFloat(
                    item.tax_perc || item.tax_1 + item.tax_2 || 0,
                );
                const taxAmount = roundNumber(
                    taxable * taxPerc,
                    roundingPrecision,
                    roundingRule,
                );
                const netAmount = roundNumber(
                    taxable + taxAmount,
                    roundingPrecision,
                    roundingRule,
                );
                const discountPerc = roundNumber(
                    itemDiscount / gross,
                    roundingPrecision,
                    roundingRule,
                );

                return {
                    ...item,
                    discount: itemDiscount,
                    discount_amount: itemDiscount,
                    vat_amt: taxAmount,
                    tax_amount: taxAmount,
                    net_amount: netAmount,
                    gross_amount: gross,
                    discount_perc: discountPerc,
                    total_taxable_amount: taxable,
                };
            });

            setTotalDiscountValue(finalDiscount);
            setDataSource(updatedData);

            try {
                const payload = {
                    sales_details: updatedData.map(item => ({
                        sales_code: text.toString(),
                        detail_code: item.key.toString(),
                        product: item.product?.id,
                        product_name: item.product?.name,
                        product_code: item.product?.code,
                        gross_amount: item.gross_amount,
                        tax_amount: item.tax_amount,
                        net_amount: item.net_amount,
                        total_taxable_amount: item.total_taxable_amount,
                        tax_1: item.tax_1,
                        tax_2: item.tax_2,
                        multipack_id: item.multipack?.id || item.multipack_id,
                        multipack_code:
                            item.multipack?.code || item.multipack_code,
                        multipack_name:
                            item.multipack?.name || item.multipack_name,
                        base_multipack_id:
                            item.base_multipack?.id || item.base_multipack_id,
                        base_multipack_code:
                            item.base_multipack?.code ||
                            item.base_multipack_code,
                        base_multipack_name:
                            item.base_multipack?.name ||
                            item.base_multipack_name,
                        multiplier: item.multiplier,
                        discount_perc: item.discount_perc,
                        discount_amount: item.discount_amount || 0,
                    })),
                };

                await postApiWithAuthToken(
                    '/api/v1/admin/salesDetails/draft',
                    payload,
                );
            } catch (error) {
                // console.error('Error updating draft:', error);
            }
        };

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>

                    <Table.Summary.Cell colSpan={2} align='end'>
                        Sales Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                        <Text strong>
                            {roundNumber(
                                isNaN(totals.totalSales)
                                    ? 0
                                    : totals.totalSales,
                                roundingPrecision,
                                roundingRule,
                            )}
                        </Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>

                    <Table.Summary.Cell colSpan={2} align='end'>
                        Total Discount
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                        {editingDiscount ? (
                            <Text strong>
                                {roundNumber(
                                    isNaN(totalDiscounValue)
                                        ? 0
                                        : totalDiscounValue,
                                    roundingRule,
                                    roundingPrecision + 1,
                                )}
                            </Text>
                        ) : (
                            <Tooltip
                                title={`Max total discount is ${dataSource.reduce(
                                    (total, item) =>
                                        total +
                                        parseFloat(item.max_discount || 0),
                                    0,
                                )}`}
                            >
                                <Input
                                    value={totalDiscounValue}
                                    onChange={e =>
                                        handleTotalDiscountChange(
                                            e.target.value,
                                        )
                                    }
                                />
                            </Tooltip>
                        )}
                    </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>

                    <Table.Summary.Cell colSpan={2} align='end'>
                        Total Taxable Amt.
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                        <Text strong>
                            {roundNumber(
                                isNaN(totals.totalVat) ? 0 : totals.totalVat,
                                roundingPrecision,
                                roundingRule,
                            )}
                        </Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>

                    <Table.Summary.Cell colSpan={2} align='end'>
                        Total Tax Amt.
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                        <Text strong>
                            {roundNumber(
                                isNaN(totals.totalTax) ? 0 : totals.totalTax,
                                roundingPrecision,
                                roundingRule,
                            )}
                        </Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>

                    <Table.Summary.Cell colSpan={2} align='end'>
                        Net Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                        <Text strong>
                            {roundNumber(
                                isNaN(totals.totalNetAmount)
                                    ? 0
                                    : totals.totalNetAmount,
                                roundingPrecision,
                                roundingRule,
                            )}
                        </Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };
    return (
        <div>
            <Button
                style={{border: 0}}
                onClick={() => {
                    history.push(
                        sales_code ? `sales-draftSummary` : `sales-summary`,
                    );
                }}
            >
                <LeftOutlined />
            </Button>
            <Form
                form={form}
                name='new-sale-entry-form'
                onFinish={onFinish}
                layout='inline'
                style={{width: '100%'}}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Descriptions
                        title={
                            <div style={{textAlign: 'left'}}>
                                {sales_code
                                    ? `Edit Draft Sales`
                                    : `Add New Sale`}
                            </div>
                        }
                        bordered
                        style={{margin: '8px', width: '100%'}}
                        column={{
                            xxl: 3,
                            xl: 3,
                            lg: 3,
                            md: 3,
                            sm: 2,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label='Invoice No'>
                            {text}
                        </Descriptions.Item>
                        <Form.Item name='date' label='Date'>
                            <DatePicker
                                value={moment()}
                                disabled
                                style={{pointerEvents: 'none'}} // Disable pointer events to prevent interaction
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}
                            />
                        </Form.Item>
                        {customerField}
                        <Form.Item
                            name='route_id'
                            label={
                                <span>
                                    Route <span style={{color: 'red'}}>*</span>
                                </span>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a route',
                                },
                            ]}
                            style={{
                                backgroundColor: '#f9faff',
                                padding: '8px',
                                borderRadius: '6px',
                                marginBottom: '16px',
                            }}
                        >
                            {draftSummary[0]?.route_name ? (
                                <span>{draftSummary[0]?.route_name}</span>
                            ) : (
                                <Select
                                    style={{width: '200px'}}
                                    showSearch
                                    placeholder='Select Route'
                                    optionFilterProp='children'
                                    filterOption={(input, option) => {
                                        const childrenValue =
                                            option?.children
                                                ?.toString()
                                                .toLowerCase() || '';
                                        return (
                                            childrenValue.indexOf(
                                                input.toLowerCase(),
                                            ) >= 0
                                        );
                                    }}
                                    disabled={confirm}
                                    onSelect={handleRouteSelect}
                                >
                                    {routeDet.map(route => (
                                        <Select.Option
                                            key={route.id}
                                            value={route.id}
                                            disabled={route.available !== true}
                                        >
                                            {route.available !== true ? (
                                                <Tooltip
                                                    title='Currently live routes are disabled'
                                                    color='geekblue'
                                                >
                                                    <span>{route.name}</span>
                                                </Tooltip>
                                            ) : route.code ? (
                                                `${route.code}_${route.name}`
                                            ) : (
                                                route.name
                                            )}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item
                            name='salesman_id'
                            label='Salesman'
                            style={{
                                backgroundColor: '#f9faff',
                                padding: '8px',
                                borderRadius: '6px',
                                marginBottom: '16px',
                            }}
                        >
                            {draftSummary[0]?.salesman_name ? (
                                <Tooltip
                                    title='Salesman is pre-filled from draft'
                                    color='geekblue'
                                >
                                    <span>
                                        {draftSummary[0]?.salesman_name}
                                    </span>
                                </Tooltip>
                            ) : (
                                <Select
                                    style={{width: '200px'}}
                                    showSearch
                                    placeholder='Select Salesman'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!route} // Disable if no route is selected
                                    onSelect={salesmanId => {
                                        const selectedSalesman = salesman.find(
                                            s => s.id === salesmanId,
                                        );
                                        setSelectedSalesman(selectedSalesman);
                                    }}
                                >
                                    {salesman.map(salesman => (
                                        <Select.Option
                                            key={salesman.id}
                                            value={salesman.id}
                                        >
                                            {salesman.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item
                            name='currency_id'
                            label={
                                <span>
                                    Currency{' '}
                                    <span style={{color: 'red'}}>*</span>
                                </span>
                            }
                            style={{
                                backgroundColor: '#f9faff',
                                padding: '8px',
                                borderRadius: '6px',
                                marginBottom: '16px',
                            }}
                        >
                            {draftSummary[0]?.currency ? (
                                <Tooltip
                                    title='Currency is pre-filled from draft'
                                    color='geekblue'
                                >
                                    <span>{draftSummary[0]?.currency} </span>
                                </Tooltip>
                            ) : (
                                <Select
                                    style={{width: '200px'}}
                                    showSearch
                                    placeholder='Select Currency'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={selectedCurrency}
                                    onSelect={currencyId => {
                                        const selectedCurrency =
                                            currencyDet.find(
                                                currency =>
                                                    currency.id === currencyId,
                                            );
                                        setSelectedCurrency(currencyId); // Update selected currency ID
                                        setSelectedCurrencyName(
                                            selectedCurrency.name,
                                        ); // Update selected currency name
                                        setSelectedCurrencyCode(
                                            selectedCurrency.code,
                                        ); // Update selected currency code
                                    }}
                                >
                                    {currencyDet.map(currency => (
                                        <Select.Option
                                            key={currency.id}
                                            value={currency.id}
                                        >
                                            {currency.name} ({currency.symbol})
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        {transTypeField}
                        <Descriptions.Item label='Tax No.'>
                            {vatRegNo}
                        </Descriptions.Item>

                        <Form.Item
                            name='remarks'
                            label='Remarks'
                            style={{
                                backgroundColor: '#f9faff',
                                padding: '8px',
                                borderRadius: '6px',
                            }}
                        >
                            <TextArea
                                disabled={confirm}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                }}
                                value={remarks}
                                onChange={e => setRemarks(e.target.value)}
                            />
                        </Form.Item>
                    </Descriptions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            marginTop: '20px',
                        }}
                    >
                        {!confirm && customer && route && transName && (
                            <Button
                                type='primary'
                                onClick={() => {
                                    // setConfirm(true);
                                    form.submit();
                                }}
                            >
                                Confirm and Proceed
                            </Button>
                        )}
                    </div>
                </div>
            </Form>
            <div style={{marginTop: 16}}>
                <Row gutter={[16, 16]} justify='end'>
                    {showTable && (
                        <Col>
                            <Button
                                type='primary'
                                style={{
                                    marginRight: 8,
                                    border: '1px solid black',
                                    // backgroundColor: '#1890ff',
                                    color: 'white',
                                }}
                                onClick={FinalSubmit}
                                onMouseEnter={e =>
                                    (e.currentTarget.style.backgroundColor =
                                        '#40a9ff')
                                }
                                onMouseLeave={e =>
                                    (e.currentTarget.style.backgroundColor =
                                        '#1890ff')
                                }
                            >
                                Confirm
                            </Button>
                        </Col>
                    )}
                    {editable && confirm && (
                        <Col>
                            <Button
                                type='default'
                                style={{
                                    marginBottom: 16,
                                    marginLeft: 10,
                                    width: '180px',
                                }}
                                onClick={() => {
                                    setConfirm(false);
                                    // handleRemoveData();
                                }}
                            >
                                Edit Customer or Route
                            </Button>
                        </Col>
                    )}
                    {confirm && (
                        <Col>
                            <Button
                                onClick={handleAdd}
                                type='default'
                                style={{
                                    marginBottom: 16,
                                    marginLeft: 20,
                                }}
                                disabled={grSelected}
                            >
                                Add Product
                            </Button>
                        </Col>
                    )}
                    {grSelected && (
                        <Col>
                            <Button
                                type='primary'
                                style={{
                                    marginBottom: 16,
                                    marginLeft: 20,
                                }}
                                onClick={resetGr}
                            >
                                Reset
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>

            {showTable && (
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columnss}
                    pagination={false}
                    style={{marginBottom: '10px', marginTop: '50px'}}
                    summary={dataSource ? summaryRow : []}
                />
            )}

            {/* <Table summary={summaryRow} style={{marginTop: '10px'}} /> */}

            {/* modal window for productSelect */}
            <Modal
                open={productModal}
                onCancel={() => {
                    handleProductModalclose();
                    setSearchInput('');
                    setFilterOption('name'); // Reset filter option
                }}
                footer={null}
                style={{top: '10%'}} // Adjust the top value as needed
            >
                <h2>Product List</h2>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Input
                        placeholder={`Search By ${filterOption} ....`}
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        style={{marginRight: 10}}
                        ref={inputRef}
                    />
                    <Select
                        value={filterOption}
                        onChange={value => setFilterOption(value)}
                        style={{marginRight: 10}}
                    >
                        <Select.Option value='name'>Name</Select.Option>
                        <Select.Option value='code'>Code</Select.Option>
                        <Select.Option value='category'>Category</Select.Option>
                    </Select>
                    <Button
                        onClick={() => {
                            setSearchInput('');
                            setFilterOption('name'); // set default as name
                        }}
                    >
                        Reset
                    </Button>
                </div>
                <div style={{maxHeight: 300, overflowY: 'scroll'}}>
                    {productList
                        .filter(product => {
                            const filterValue =
                                filterOption === 'name'
                                    ? product.name
                                    : filterOption === 'code'
                                      ? product.code
                                      : product.category;

                            // Check if filterValue is a string before calling toLowerCase()
                            if (typeof filterValue === 'string') {
                                return filterValue
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase());
                            }
                            // If filterValue is not a string, don't include it in the filtered list
                            return false;
                        })
                        .map(product => (
                            <div
                                key={product.id}
                                style={{
                                    marginBottom: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid #adbab8',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    margin: '5px',
                                }}
                            >
                                <span>
                                    {product.name} ({product.code}) -{' '}
                                    {product.category}
                                </span>
                                <Button
                                    type='primary'
                                    onClick={() =>
                                        handleProductSelect(product.id)
                                    }
                                    style={{marginLeft: 10}}
                                >
                                    Add
                                </Button>
                            </div>
                        ))}
                </div>
            </Modal>

            <Modal
                open={collectionModalVisible}
                title={
                    <div style={{textAlign: 'center'}}>Collection Payment</div>
                }
                onCancel={() => setCollectionModalVisible(false)}
                footer={null}
            >
                <Form
                    name='dynamic_form_nest_item'
                    autoComplete='off'
                    onFinish={onFix}
                    style={{maxWidth: 600}}
                >
                    <Form.List name='collection' initialValue={[{}]}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map(
                                    (
                                        {key, name, fieldKey, ...restField},
                                        index,
                                    ) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align='baseline'
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'paymentType']}
                                                fieldKey={[
                                                    fieldKey,
                                                    'paymentType',
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Missing payment type',
                                                    },
                                                ]}
                                                style={{marginTop: '10px'}}
                                            >
                                                <Select
                                                    placeholder='Select payment type'
                                                    onChange={value =>
                                                        handleSelectChange(
                                                            value,
                                                            index,
                                                        )
                                                    }
                                                    style={{width: 200}}
                                                >
                                                    {paymentTypes.map(type => (
                                                        <Option
                                                            key={type.id}
                                                            disabled={selectedPaymentTypes.includes(
                                                                type.id,
                                                            )}
                                                            value={type.id}
                                                        >
                                                            {type.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'amount']}
                                                fieldKey={[fieldKey, 'amount']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Missing amount',
                                                    },
                                                    {
                                                        validator(_, value) {
                                                            const currentEnteredAmount =
                                                                parseFloat(
                                                                    value,
                                                                ) || 0;
                                                            const totalEnteredAmounts =
                                                                totalAmounts.reduce(
                                                                    (
                                                                        total,
                                                                        amount,
                                                                        idx,
                                                                    ) =>
                                                                        total +
                                                                        (idx !==
                                                                        index
                                                                            ? amount
                                                                            : 0),
                                                                    0,
                                                                );

                                                            const remainingBalance =
                                                                initialTotalBalance -
                                                                totalEnteredAmounts;

                                                            if (
                                                                totalEnteredAmounts +
                                                                    currentEnteredAmount >
                                                                initialTotalBalance
                                                            ) {
                                                                return Promise.reject(
                                                                    `Total amount exceeds available balance. You can enter up to ${remainingBalance.toFixed(
                                                                        2,
                                                                    )}`,
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type='number'
                                                    placeholder='Amount'
                                                    style={{width: 120}}
                                                    onChange={e =>
                                                        handleAmountChange(
                                                            e.target.value,
                                                            index,
                                                        )
                                                    }
                                                />
                                            </Form.Item>

                                            <MinusCircleOutlined
                                                onClick={() =>
                                                    handleRemoveRows(
                                                        index,
                                                        remove,
                                                    )
                                                }
                                            />
                                        </Space>
                                    ),
                                )}
                                {paymentTypes.length !==
                                    selectedPaymentTypes.length && (
                                    <Form.Item>
                                        <Button
                                            type='dashed'
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Collection
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>

                    <Form.Item
                        label='Total Balance'
                        style={{
                            marginBottom: '10px',
                            textAlign: 'center',
                        }}
                    >
                        <Input
                            value={totalBalance.toFixed(2)}
                            disabled
                            style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                border: 'none',
                                padding: '4px',
                                textAlign: 'center',
                                width: '200px',
                            }}
                            size='small'
                        />
                    </Form.Item>

                    <Form.Item style={{textAlign: 'right'}}>
                        <Button
                            type='primary'
                            htmlType='submit'
                            disabled={totalBalance !== 0}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Sales Invoices'
                open={eInvoiceModalVisible}
                onCancel={handleEinvoiceModalCancel}
                footer={null}
                width='80%' // Adjust the width of the modal as needed
                maskClosable={false}
            >
                <SalesSummaryModal
                    selectedSalesType={selectedSalesType}
                    routeCode={routeCode}
                    customerID={customer?.id}
                    salesmanCode={selectedSalesman?.code}
                    setGrSalesDetails={setGrSalesDetails}
                    onCancel={handleEinvoiceModalCancel}
                    setDataSource={setDataSource}
                    setGrMultipackValues={setGrMultipackValues}
                    setSelectProductId={setSelectProductId}
                    setBillingReference={setBillingReference}
                    setTransName={setTransName}
                />
            </Modal>

            {confirmFinal && <InvoiceView Invoice={invoiceData} />}
            {collectionFinal && (
                <CollectionInvoiceView Invoice={collectionData} />
            )}
        </div>
    );
};

export default NewSaleEntry;
