import React, {Fragment, Suspense, lazy, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {
    CompatRouter,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom-v5-compat';
import Loading from 'components/Layouts/Loading';
import ContentView from 'components/Layouts/ContentView';
import {Spin, message} from 'antd';
import {connect} from 'react-redux';

import {loadUser} from 'actions/authAction';

import ProtectedRoute from 'routing/ProtectedRoute';
import NewSale from 'components/Transactions/Sales/NewSale';

// import SalesDraftsummary from 'pages/Transactions/Sales/salesDraft';
import CustomerLocation from 'components/CustomerMasters/CustomerLocation/CustomerLocationTable';
import CompanyContentView from 'components/Layouts/CompanyContentView';
import {LoadingOutlined} from '@ant-design/icons';
import AddQuotations from 'components/Transactions/Quotations/AddQuotations';

const NotFoundPage = lazy(() => import('pages/404'));
const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const LiveDashboard = lazy(() => import('pages/Dashboard/LiveDashboard'));
const SalesmanDashboard = lazy(
    () => import('pages/Dashboard/SalesmanDashboard'),
);
// const VanforceInteligence = lazy(() =>
//     import('pages/Dashboard/VanforceInteligence')
// );
const PriceKeyReports = lazy(() => import('pages/Reports/PriceKeyReports'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ForgotPassword/ResetPassword'));

const Area = lazy(() => import('pages/LocaleMasters/Area'));
const CurrencyConversion = lazy(
    () => import('pages/LocaleMasters/CurrencyConversion'),
);
const CountryManager = lazy(() => import('pages/LocaleMasters/CountryManager'));
const Region = lazy(() => import('pages/LocaleMasters/Region'));
const Customer = lazy(() => import('pages/CustomerMasters/Customer'));
const CustomerForm = lazy(
    () => import('pages/CustomerMasters/Customer/addForm'),
);
const CasualCustomer = lazy(
    () => import('pages/CustomerMasters/CasualCustomer'),
);
const CustomerTypes = lazy(() => import('pages/CustomerMasters/CustomerTypes'));
const CustomerInventory = lazy(
    () => import('pages/CustomerMasters/CustomerInventory'),
);
const CustomerInventoryDetails = lazy(
    () => import('pages/CustomerMasters/CustomerInventory/details'),
);
const CustomerWallet = lazy(
    () => import('pages/CustomerMasters/CustomerWallet'),
);
const CustomerTrialBalance = lazy(
    () => import('pages/CustomerMasters/CustomerTrialBalance'),
);
const Manager = lazy(() => import('pages/UserMasters/Manager'));
const UserRoles = lazy(() => import('pages/UserMasters/UserRoles'));
const Users = lazy(() => import('pages/UserMasters/Users'));
const UsersForm = lazy(() => import('pages/UserMasters/Users/addForm'));
const SalesmanLog = lazy(() => import('pages/UserMasters/Users/log'));
const PreviousSalesmanLog = lazy(
    () => import('pages/UserMasters/Users/PreviousLog'),
);
const ChatView = lazy(() => import('pages/UserMasters/Users/chat'));
const Product = lazy(() => import('pages/ProductMasters/Product'));
const ProductCategory = lazy(
    () => import('pages/ProductMasters/ProductCategory'),
);
const ProductMultipack = lazy(
    () => import('pages/ProductMasters/ProductMultipack'),
);
const ProductSubcategory = lazy(
    () => import('pages/ProductMasters/ProductSubcategory'),
);
const TaxCategory = lazy(() => import('pages/ProductMasters/TaxCategory'));
const PriceKeyGroup = lazy(() => import('pages/PriceMasters/PriceKey'));
const PriceKey = lazy(() => import('pages/PriceMasters/PriceKey/details'));
const CustomerPrice = lazy(() => import('pages/PriceMasters/CustomerPrice'));
const RoutePrice = lazy(() => import('pages/PriceMasters/RoutePrice'));
const ProductPrice = lazy(() => import('pages/PriceMasters/ProductPrice'));
const RouteMaster = lazy(() => import('pages/RouteMasters/Route'));
const RouteForm = lazy(() => import('pages/RouteMasters/Route/addFrom'));
const DailyPriceUpdate = lazy(
    () => import('pages/PriceMasters/DailyPriceUpdate'),
);
const RouteJourneyPlan = lazy(
    () => import('pages/RouteMasters/RouteJourneyPlan'),
);
const RouteLastCode = lazy(() => import('pages/RouteMasters/RouteLastCode'));
const EndOfDayReports = lazy(
    () => import('pages/RouteMasters/EndOfDayReports'),
);
const InventoryTable = lazy(() => import('pages/RouteMasters/Inventory'));
const InventoryDetails = lazy(
    () => import('pages/RouteMasters/Inventory/details'),
);
const InventoryLogTable = lazy(
    () => import('pages/RouteMasters/InventoryLogTable'),
);

const CustomDocumentCode = lazy(
    () => import('pages/RouteMasters/CustomDocumentCode'),
);
const Branch = lazy(() => import('pages/RouteMasters/Branch'));
const Warehouse = lazy(() => import('pages/RouteMasters/Warehouse'));
const DayTrackLog = lazy(() => import('pages/RouteMasters/DayTrackLogTable'));
const ReasonsTable = lazy(() => import('pages/RouteMasters/Reasons'));
const RouteJourneyPlanLog = lazy(
    () => import('pages/RouteMasters/RouteJourneyPlanLog'),
);
const RouteJourneyDetails = lazy(
    () => import('pages/RouteMasters/RouteJourneyPlanLog/details'),
);

const CompanySettings = lazy(() => import('pages/CompanySettings'));
const MobileSettings = lazy(
    () => import('pages/CompanySettings/MobileSettings'),
);
const TransactionSettings = lazy(
    () => import('pages/CompanySettings/TransactionSettings'),
);
const ERPSettings = lazy(() => import('pages/CompanySettings/ERPSettings'));
const PrintSettings = lazy(() => import('pages/CompanySettings/PrintSettings'));
const RouteSettings = lazy(
    () => import('pages/CompanySettings/RouteJourneySettings'),
);
const InventorySettings = lazy(
    () => import('pages/CompanySettings/InventorySettings'),
);
const EodReportsSettings = lazy(
    () => import('pages/CompanySettings/EodReportsSettings'),
);
const DayTrackSettings = lazy(
    () => import('pages/CompanySettings/DayTrackSettings'),
);
const PaymentTypeSettings = lazy(
    () => import('pages/CompanySettings/PaymentTypeSettings'),
);
const DownloadTemplate = lazy(
    () => import('pages/CompanySettings/DownloadTemplate'),
);

const CostCentre = lazy(() => import('pages/Expanses/CostCentre'));
const ExpenseType = lazy(() => import('pages/Expanses/ExpenseType'));
const ExpenseSummary = lazy(() => import('pages/Expanses/ExpenseEntry'));
const ExpenseDetails = lazy(
    () => import('pages/Expanses/ExpenseEntry/details'),
);

const QuotationsSummary = lazy(() => import('pages/Transactions/Quotations'));
const QuotationsDetails = lazy(
    () => import('pages/Transactions/Quotations/details'),
);
// const NewQuotaion = lazy(
//     () => import('pages/Transactions/Quotations/newQuotations'),
// );
const NewSalesDetails = lazy(() => import('pages/Transactions/Sales/newSales'));
const SalesSummary = lazy(() => import('pages/Transactions/Sales'));
const SalesDetails = lazy(() => import('pages/Transactions/Sales/details'));
const SalesDraftsummary = lazy(
    () => import('pages/Transactions/Sales/salesDraft'),
);
const CollectionsSummary = lazy(() => import('pages/Transactions/Collections'));
const CollectionsDetails = lazy(
    () => import('pages/Transactions/Collections/details'),
);
const PendingQuotations = lazy(
    () => import('pages/Transactions/PendingQuotations'),
);
const AdvanceCollections = lazy(
    () => import('pages/Transactions/AdvanceCollections'),
);
const AddAdvanceCollection = lazy(
    () => import('pages/Transactions/AdvanceCollections/addCollection'),
);
const Settlements = lazy(() => import('pages/Transactions/Settlements'));
const LoadSummary = lazy(() => import('pages/Transactions/Load'));
const SuggestedLoad = lazy(
    () => import('pages/Transactions/Load/suggestedLoad'),
);
const SuggestedLoadSummary = lazy(
    () => import('pages/Transactions/Load/suggestedLoadSummary'),
);
const SuggestedLoadDetail = lazy(
    () => import('pages/Transactions/Load/suggestedLoadDetails'),
);
const LoadDetails = lazy(() => import('pages/Transactions/Load/details'));
const AddLoad = lazy(() => import('pages/Transactions/Load/newLoad'));
const UnloadSummary = lazy(() => import('pages/Transactions/Unload'));
const UnloadDetails = lazy(() => import('pages/Transactions/Unload/details'));
const UnLoadAdd = lazy(() => import('pages/Transactions/Unload/newUnload'));
const AddCollection = lazy(
    () => import('components/Transactions/Collections/addCollection'),
);
const StockAdjustmentLogs = lazy(
    () => import('pages/Transactions/StockAdjustmentLogs'),
);
const SalesmanDamageSummary = lazy(
    () => import('pages/Transactions/SalesmanDamage'),
);
const SalesmanDamageDetails = lazy(
    () => import('pages/Transactions/SalesmanDamage/details'),
);
const SalesmanDamageCreate = lazy(
    () => import('pages/Transactions/SalesmanDamage/addDamage'),
);
const SalesmanDamageSettlement = lazy(
    () => import('pages/Transactions/SalesmanDamage/Settlement'),
);
const SalesmanDamageSettlementSummary = lazy(
    () => import('pages/Transactions/SalesmanDamage/SettlementSummary'),
);
const SalesmanDamageSettlementDetails = lazy(
    () => import('pages/Transactions/SalesmanDamage/SettlementDetail'),
);
const CreditDebitNotes = lazy(
    () => import('pages/Transactions/CreditDebitNotes'),
);
const CreditDebitDetails = lazy(
    () => import('pages/Transactions/CreditDebitNotes/details'),
);
const AddCreditDebitNote = lazy(
    () => import('pages/Transactions/CreditDebitNotes/addCreditDebitNote'),
);
const CustomerStatement = lazy(
    () =>
        import(
            'components/CustomerMasters/CustomerStatement/CustomerStatementTable'
        ),
);
const TransactionsOtp = lazy(
    () => import('pages/Transactions/TransactionsOtp'),
);

const RegionOnboarding = lazy(() => import('pages/Onboarding/Region'));
const AreaOnboarding = lazy(() => import('pages/Onboarding/Area'));
const WarehouseOnboarding = lazy(() => import('pages/Onboarding/warehouse'));
const RouteOnboarding = lazy(() => import('pages/Onboarding/Route'));
const BranchOnboarding = lazy(() => import('pages/Onboarding/Branch'));
const ProductOnboarding = lazy(() => import('pages/Onboarding/Product'));
const ProductCategoryOnboarding = lazy(
    () => import('pages/Onboarding/ProductCategory'),
);
const ProductSubcategoryOnboarding = lazy(
    () => import('pages/Onboarding/ProductSubcategory'),
);
const ProductMultipackOnboarding = lazy(
    () => import('pages/Onboarding/ProductMultipack'),
);

const ProductPriceOnboarding = lazy(
    () => import('pages/Onboarding/ProductPrice'),
);
const CustomerOnboarding = lazy(() => import('pages/Onboarding/Customer'));
const CustomDocumentCodeOnboarding = lazy(
    () => import('pages/Onboarding/CustomDocumentCode'),
);
const CompanySettingsOnboarding = lazy(
    () => import('pages/Onboarding/CompanySettings'),
);
const FullScreenMap = lazy(() => import('pages/Dashboard/FullScreenMap'));
const UsersOnboarding = lazy(() => import('pages/Onboarding/Users'));

const AppRouter = ({loadUser}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            let result = await loadUser();
            if (result && result.status === 'error') {
                if (result.data.errors.length) {
                    message.error(result.data.errors[0].msg);
                } else {
                    message.error(result.data.errors);
                }
            } else if (!result && window.location.pathname !== '/login') {
                message.warning('Please log in!');
            }
            if (result && window.location.pathname === '/login') {
                setIsLogin(true);
                console.log('okay');
            }
            setIsLoading(false);
        })();
    }, []);

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin
                    indicator={<LoadingOutlined style={{fontSize: 50}} spin />}
                />
            </div>
        );
    }

    return (
        <Fragment>
            <BrowserRouter>
                <CompatRouter>
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route
                                path='/login'
                                element={
                                    isLogin ? (
                                        <Navigate replace to={'/'} />
                                    ) : (
                                        <Login />
                                    )
                                }
                            />
                            <Route
                                path='/register'
                                element={
                                    isLogin ? (
                                        <Navigate replace to={'/'} />
                                    ) : (
                                        <Register />
                                    )
                                }
                            />
                            <Route
                                path='/forgot-password'
                                element={
                                    isLogin ? (
                                        <Navigate replace to={'/'} />
                                    ) : (
                                        <ForgotPassword />
                                    )
                                }
                            />
                            <Route
                                path='/reset-password/:token'
                                element={
                                    isLogin ? (
                                        <Navigate replace to={'/'} />
                                    ) : (
                                        <ResetPassword />
                                    )
                                }
                            />
                            <Route
                                path='/company-settings'
                                element={
                                    <ProtectedRoute>
                                        <CompanyContentView />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<CompanySettings />} />
                                <Route
                                    path='mobile-settings'
                                    element={<MobileSettings />}
                                />
                                <Route
                                    path='transaction-settings'
                                    element={<TransactionSettings />}
                                />
                                <Route
                                    path='print-settings'
                                    element={<PrintSettings />}
                                />
                                <Route
                                    path='route-settings'
                                    element={<RouteSettings />}
                                />
                                <Route
                                    path='inventory-settings'
                                    element={<InventorySettings />}
                                />
                                <Route
                                    path='erp-settings'
                                    element={<ERPSettings />}
                                />
                                <Route
                                    path='eod-settings'
                                    element={<EodReportsSettings />}
                                />

                                <Route
                                    path='daytrack-settings'
                                    element={<DayTrackSettings />}
                                />
                                <Route
                                    path='download-templates'
                                    element={<DownloadTemplate />}
                                />
                                <Route
                                    path='custom-document-code'
                                    element={<CustomDocumentCode />}
                                />
                                <Route
                                    path='payment-type-settings'
                                    element={<PaymentTypeSettings />}
                                />
                            </Route>

                            <Route
                                path='/'
                                element={
                                    <ProtectedRoute>
                                        <ContentView />
                                    </ProtectedRoute>
                                }
                            >
                                {/* Dashboard */}
                                <Route index element={<Dashboard />} />
                                <Route
                                    path='live-dasboard'
                                    element={<LiveDashboard />}
                                />
                                <Route
                                    path='salesman-dashboard'
                                    element={<SalesmanDashboard />}
                                />
                                {/* <Route
                                    path="vi-board"
                                    element={<VanforceInteligence />}
                                /> */}
                                <Route
                                    path='full-screen-map'
                                    element={<FullScreenMap />}
                                />

                                {/* Reports */}
                                <Route path='reports'>
                                    <Route
                                        path='price-key'
                                        element={<PriceKeyReports />}
                                    />
                                </Route>

                                {/* Locale */}
                                <Route path='area' element={<Area />} />
                                <Route path='region' element={<Region />} />
                                <Route
                                    path='currency-conversion'
                                    element={<CurrencyConversion />}
                                />
                                <Route
                                    path='country-managers'
                                    element={<CountryManager />}
                                />

                                {/* User */}
                                <Route
                                    path='user-roles'
                                    element={<UserRoles />}
                                />
                                <Route path='manager' element={<Manager />} />
                                <Route path='users'>
                                    <Route index element={<Users />} />
                                    <Route
                                        path='log'
                                        element={<SalesmanLog />}
                                    />
                                    <Route
                                        path='previous-log'
                                        element={<PreviousSalesmanLog />}
                                    />
                                    <Route path='add' element={<UsersForm />} />
                                    <Route path='chat' element={<ChatView />} />
                                </Route>

                                {/* Customer */}
                                <Route path='customer'>
                                    <Route index element={<Customer />} />
                                    <Route
                                        path='add'
                                        element={<CustomerForm />}
                                    />
                                </Route>
                                <Route
                                    path='casual-customer'
                                    element={<CasualCustomer />}
                                />
                                <Route
                                    path='customer-types'
                                    element={<CustomerTypes />}
                                />
                                <Route
                                    path='customer-location'
                                    element={<CustomerLocation />}
                                />
                                <Route
                                    path='customer-inventory'
                                    element={<CustomerInventory />}
                                />
                                <Route
                                    path='customer-inventory-details'
                                    element={<CustomerInventoryDetails />}
                                />
                                <Route
                                    path='customer-statement'
                                    element={<CustomerStatement />}
                                />
                                <Route
                                    path='customer-wallet'
                                    element={<CustomerWallet />}
                                />
                                <Route
                                    path='customer-trial-balance'
                                    element={<CustomerTrialBalance />}
                                />

                                {/* Product */}
                                <Route path='product' element={<Product />} />
                                <Route
                                    path='product-category'
                                    element={<ProductCategory />}
                                />
                                <Route
                                    path='product-multipack'
                                    element={<ProductMultipack />}
                                />
                                <Route
                                    path='product-subcategory'
                                    element={<ProductSubcategory />}
                                />
                                <Route
                                    path='tax-category'
                                    element={<TaxCategory />}
                                />

                                {/* Price */}
                                <Route
                                    path='price-key-group'
                                    element={<PriceKeyGroup />}
                                />
                                <Route
                                    path='price-key'
                                    element={<PriceKey />}
                                />
                                <Route
                                    path='customer-price'
                                    element={<CustomerPrice />}
                                />
                                <Route
                                    path='route-price'
                                    element={<RoutePrice />}
                                />
                                <Route
                                    path='product-price'
                                    element={<ProductPrice />}
                                />
                                <Route
                                    path='daily-price-update'
                                    element={<DailyPriceUpdate />}
                                />

                                {/* Route */}
                                <Route path='route'>
                                    <Route index element={<RouteMaster />} />
                                    <Route path='add' element={<RouteForm />} />
                                </Route>
                                <Route
                                    path='route-journey-plan'
                                    element={<RouteJourneyPlan />}
                                />
                                <Route
                                    path='route-last-code'
                                    element={<RouteLastCode />}
                                />

                                <Route
                                    path='route-journey-plan-log'
                                    element={<RouteJourneyPlanLog />}
                                />
                                <Route
                                    path='route-journey-plan-log-details'
                                    element={<RouteJourneyDetails />}
                                />
                                <Route
                                    path='end-of-day-reports'
                                    element={<EndOfDayReports />}
                                />
                                <Route
                                    path='inventory'
                                    element={<InventoryTable />}
                                />
                                <Route
                                    path='reasons'
                                    element={<ReasonsTable />}
                                />
                                <Route
                                    path='inventory-details'
                                    element={<InventoryDetails />}
                                />
                                <Route
                                    path='inventory-log'
                                    element={<InventoryLogTable />}
                                />
                                <Route
                                    path='day-track-log'
                                    element={<DayTrackLog />}
                                />
                                <Route path='branch' element={<Branch />} />
                                <Route
                                    path='warehouse'
                                    element={<Warehouse />}
                                />

                                {/* Expanses */}
                                <Route
                                    path='cost-centre'
                                    element={<CostCentre />}
                                />
                                <Route
                                    path='expense-type'
                                    element={<ExpenseType />}
                                />
                                <Route
                                    path='expense-summary'
                                    element={<ExpenseSummary />}
                                />
                                <Route
                                    path='expense-details'
                                    element={<ExpenseDetails />}
                                />

                                {/* Transactions */}
                                <Route
                                    path='sales-summary'
                                    element={<SalesSummary />}
                                />
                                <Route
                                    path='newSales'
                                    element={<NewSalesDetails />}
                                />

                                <Route
                                    path='sales-details'
                                    element={<SalesDetails />}
                                />
                                <Route
                                    path='sales-draftSummary'
                                    element={<SalesDraftsummary />}
                                />
                                <Route
                                    path='collections-summary'
                                    element={<CollectionsSummary />}
                                />
                                <Route
                                    path='collections-details'
                                    element={<CollectionsDetails />}
                                />
                                <Route
                                    path='addCollection'
                                    element={<AddCollection />}
                                />
                                <Route
                                    path='quotations-summary'
                                    element={<QuotationsSummary />}
                                />
                                <Route
                                    path='quotations-details'
                                    element={<QuotationsDetails />}
                                />
                                <Route
                                    path='new-quotation'
                                    element={<AddQuotations />}
                                />

                                <Route
                                    path='load-summary'
                                    element={<LoadSummary />}
                                />
                                <Route path='load-add' element={<AddLoad />} />
                                <Route
                                    path='load-details'
                                    element={<LoadDetails />}
                                />
                                <Route
                                    path='suggested-load'
                                    element={<SuggestedLoad />}
                                />
                                <Route
                                    path='suggested-loadSummary'
                                    element={<SuggestedLoadSummary />}
                                />
                                <Route
                                    path='suggested-loadDetail'
                                    element={<SuggestedLoadDetail />}
                                />
                                <Route
                                    path='unload-summary'
                                    element={<UnloadSummary />}
                                />

                                <Route
                                    path='unload-details'
                                    element={<UnloadDetails />}
                                />
                                <Route
                                    path='unLoad-add'
                                    element={<UnLoadAdd />}
                                />
                                <Route
                                    path='pending-quotations'
                                    element={<PendingQuotations />}
                                />
                                <Route
                                    path='advance-collections'
                                    element={<AdvanceCollections />}
                                />
                                <Route
                                    path='add-advance'
                                    element={<AddAdvanceCollection />}
                                />
                                <Route
                                    path='settlements'
                                    element={<Settlements />}
                                />
                                <Route path='newSales' element={<NewSale />} />

                                <Route
                                    path='stock-adjustment-logs'
                                    element={<StockAdjustmentLogs />}
                                />
                                <Route
                                    path='salesman-damage'
                                    element={<SalesmanDamageSummary />}
                                />
                                <Route
                                    path='salesman-damage-details'
                                    element={<SalesmanDamageDetails />}
                                />
                                <Route
                                    path='add-salesman-damage'
                                    element={<SalesmanDamageCreate />}
                                />
                                <Route
                                    path='salesman-damage-settlement'
                                    element={<SalesmanDamageSettlement />}
                                />
                                <Route
                                    path='salesman-damage-settlement-summary'
                                    element={
                                        <SalesmanDamageSettlementSummary />
                                    }
                                />
                                <Route
                                    path='salesman-damage-settlement-details'
                                    element={
                                        <SalesmanDamageSettlementDetails />
                                    }
                                />
                                <Route
                                    path='credit-debit-notes'
                                    element={<CreditDebitNotes />}
                                />
                                <Route
                                    path='credit-debit-details'
                                    element={<CreditDebitDetails />}
                                />
                                <Route
                                    path='add-credit-debit-note'
                                    element={<AddCreditDebitNote />}
                                />
                                <Route
                                    path='transactions-otp'
                                    element={<TransactionsOtp />}
                                />
                            </Route>

                            {/* Onboarding */}
                            <Route
                                path='onboarding'
                                element={
                                    <ProtectedRoute>
                                        <ContentView disableSider={true} />
                                    </ProtectedRoute>
                                }
                            >
                                <Route
                                    path='region'
                                    element={<RegionOnboarding />}
                                />
                                <Route
                                    path='area'
                                    element={<AreaOnboarding />}
                                />
                                <Route
                                    path='warehouse'
                                    element={<WarehouseOnboarding />}
                                />
                                <Route
                                    path='route'
                                    element={<RouteOnboarding />}
                                />
                                <Route
                                    path='route/route-form'
                                    element={<RouteForm />}
                                />
                                <Route
                                    path='branch'
                                    element={<BranchOnboarding />}
                                />
                                <Route
                                    path='product-multipack'
                                    element={<ProductMultipackOnboarding />}
                                />
                                <Route
                                    path='product-category'
                                    element={<ProductCategoryOnboarding />}
                                />
                                <Route
                                    path='product-subcategory'
                                    element={<ProductSubcategoryOnboarding />}
                                />
                                <Route
                                    path='product'
                                    element={<ProductOnboarding />}
                                />
                                <Route
                                    path='product-price'
                                    element={<ProductPriceOnboarding />}
                                />
                                <Route
                                    path='customer'
                                    element={<CustomerOnboarding />}
                                />
                                <Route
                                    path='customer/customer-form'
                                    element={<CustomerForm />}
                                />
                                <Route
                                    path='custom-document-code'
                                    element={<CustomDocumentCodeOnboarding />}
                                />
                                <Route
                                    path='company-settings'
                                    element={<CompanySettingsOnboarding />}
                                />
                                <Route
                                    path='users'
                                    element={<UsersOnboarding />}
                                />
                                <Route
                                    path='users/users-form'
                                    element={<UsersForm />}
                                />
                            </Route>

                            <Route path='*' element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>
                </CompatRouter>
            </BrowserRouter>
        </Fragment>
    );
};

export default connect(null, {loadUser})(AppRouter);
