import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, Link} from 'react-router-dom';
import {Layout, Menu, Typography} from 'antd';
import {connect} from 'react-redux';
import {
    ShoppingOutlined,
    UserOutlined,
    EnvironmentOutlined,
    TransactionOutlined,
    CarOutlined,
    HomeOutlined,
    TagsOutlined,
    CreditCardOutlined,
    BarChartOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import SubMenu from 'antd/es/menu/SubMenu';

const {Sider} = Layout;

const menuItems = [
    {
        label: 'Dashboard',
        icon: <HomeOutlined />,
        children: [
            {
                label: <Link to='/'>Dashboard</Link>,
                key: '/',
            },
            {
                label: <Link to='/'>Live</Link>,
                key: '/live-dasboard',
            },
            {
                label: <Link to='/salesman-dashboard'>Salesman Dashboard</Link>,
                key: '/salesman-dashboard',
            },
            // {
            //     label: 'Vanforce Inteligence',
            //     key: '/vi-board',
            // }
        ],
    },
    {
        label: 'Reports',
        icon: <BarChartOutlined />,
        children: [
            {
                label: <Link to='/reports/price-key'>Price Key</Link>,
                key: '/reports/price-key',
            },
        ],
    },
    {
        label: 'Locale Masters',
        icon: <EnvironmentOutlined />,
        permission_name: 'locale',
        children: [
            {label: <Link to='/region'>Region</Link>, key: '/region'},
            {label: <Link to='/area'>Area</Link>, key: '/area'},
            {
                label: <Link to='/branch'>Branch</Link>,
                key: '/branch',
                permission_name: 'Branch',
            },
            {
                label: <Link to='/route'>Route</Link>,
                key: '/route',
                permission_name: 'Route',
            },
            {
                label: <Link to='/warehouse'>Warehouse</Link>,
                key: '/warehouse',
                permission_name: 'Warehouse',
            },
            {
                label: (
                    <Link to='/currency-conversion'>Currency Conversion</Link>
                ),
                key: '/currency-conversion',
            },
            {
                label: <Link to='/country-managers'>Country Managers</Link>,
                key: '/country-managers',
            },
        ],
    },
    {
        label: 'User Masters',
        icon: <UserOutlined />,
        children: [
            // {  label: 'User', key: '/user' },
            // {  label: 'Collector', key: '/collector' },
            {
                label: <Link to='/users'>Users</Link>,
                key: '/users',
                permission_name: 'users',
            },

            {
                label: <Link to='/user-roles'>User Roles</Link>,
                key: '/user-roles',
                permission_name: 'Manager',
            },
            // {
            //     label: 'Manager ',
            //     key: '/manager',
            //     permission_name: 'Manager',
            // },
        ],
    },
    {
        label: 'Customer Masters',
        icon: <TeamOutlined />,
        children: [
            {
                label: <Link to='/customer'>Customer</Link>,
                key: '/customer',
                permission_name: 'Customer',
            },
            {
                label: <Link to='/casual-customer'>Casual Customer</Link>,
                key: '/casual-customer',
                permission_name: 'Customer',
            },
            {
                label: <Link to='/customer-types'>Customer Type</Link>,
                key: '/customer-types',
                permission_name: 'Customer',
            },
            {
                label: <Link to='/customer-location'>Customer Location</Link>,
                key: '/customer-location',
                permission_name: 'Customer',
            },
            {
                label: (
                    <Link to='/customer-inventory'>Customer Inventory </Link>
                ),
                key: '/customer-inventory',
                permission_name: 'Inventory',
            },
            {
                label: <Link to='customer-statement'>Customer Statement </Link>,
                key: '/customer-statement',
                permission_name: 'Collections',
            },
            {
                label: <Link to='/customer-wallet'>Customer Wallet </Link>,
                key: '/customer-wallet',
                permission_name: 'Customer',
            },
            {
                label: (
                    <Link to='customer-trial-balance'>
                        Customer Trial Balance{' '}
                    </Link>
                ),
                key: '/customer-trial-balance',
                permission_name: 'Customer',
            },
        ],
    },
    {
        label: 'Product Masters',
        icon: <ShoppingOutlined />,
        permission_name: 'Product',
        children: [
            {
                label: <Link to='/product-multipack'>Product Multipack</Link>,
                key: '/product-multipack',
            },
            {
                label: <Link to='/tax-category'>Tax Category</Link>,
                key: '/tax-category',
            },
            {
                label: <Link to='/product-category'> Product Category </Link>,
                key: '/product-category',
            },
            {
                label: (
                    <Link to='/product-subcategory'>Product Subcategory</Link>
                ),
                key: '/product-subcategory',
            },
            {label: <Link to='/product'>Product</Link>, key: '/product'},
        ],
    },
    {
        label: 'Price Masters',
        icon: <TagsOutlined />,
        permission_name: 'Price',
        children: [
            {
                label: <Link to='/price-key-group'>Price Key</Link>,
                key: '/price-key-group',
            },
            {
                label: <Link to='/customer-price'>Customer Price</Link>,
                key: '/customer-price',
            },
            {
                label: <Link to='/route-price'>Route Price</Link>,
                key: '/route-price',
            },
            {
                label: <Link to='/product-price'>Product Price</Link>,
                key: '/product-price',
            },
            {
                label: <Link to='/daily-price-update'>Daily Price Update</Link>,
                key: '/daily-price-update',
            },
            // { path: '16', label: 'Salesman Price', key: '/salesman-price' },
            // { path: '18', label: 'Payment Type ', key: '/payment-type' },
        ],
    },

    {
        label: 'Route Masters',
        icon: <CarOutlined />,
        children: [
            {
                label: <Link to='/route-journey-plan'>Route Journey Plan</Link>,
                key: '/route-journey-plan',
                permission_name: 'Route',
            },
            {
                label: <Link to='/route-last-code'> Route Last Code </Link>,
                key: '/route-last-code',
                permission_name: 'Route',
            },
            {
                label: <Link to='/day-track-log'>Day Track Log </Link>,
                key: '/day-track-log',
                permission_name: 'Route',
            },
            {
                label: <Link to='/route-journey-plan-log'> RJP Log </Link>,
                key: '/route-journey-plan-log',
                permission_name: 'Route',
            },
            {
                label: <Link to='/end-of-day-reports'>End Of Day Reports</Link>,
                key: '/end-of-day-reports',
                permission_name: 'Route',
            },
            {
                label: <Link to='/inventory'> Inventory </Link>,
                key: '/inventory',
                permission_name: 'Route',
            },
            {
                label: <Link to='/reasons'> Reasons </Link>,
                key: '/reasons',
                permission_name: 'Route',
            },
            {
                label: <Link to='/inventory-log'> Inventory Log </Link>,
                key: '/inventory-log',
                permission_name: 'Route',
            },
        ],
    },
    {
        label: 'Expenses',
        icon: <CreditCardOutlined />,
        permission_name: 'Expenses',
        children: [
            {
                label: <Link to='/cost-centre'> Cost Centre </Link>,
                key: '/cost-centre',
            },
            {
                label: <Link to='/expense-type'> Expense Type </Link>,
                key: '/expense-type',
            },
            {
                label: <Link to='/expense-summary'> Expense Entry </Link>,
                key: '/expense-summary',
            },
        ],
    },
    {
        label: 'Transactions',
        icon: <TransactionOutlined />,
        children: [
            {
                label: <Link to='/sales-summary'>Sales</Link>,
                key: '/sales-summary',
                permission_name: 'Sales',
            },
            {
                label: <Link to='/collections-summary'>Collections</Link>,
                key: '/collections-summary',
                permission_name: 'Collections',
            },
            {
                label: <Link to='/quotations-summary'> Quotations </Link>,
                key: '/quotations-summary',
                permission_name: 'Quotations',
            },
            {
                label: (
                    <Link to='/pending-quotations'> Pending Quotations </Link>
                ),
                key: '/pending-quotations',
                permission_name: 'Quotations',
            },
            {
                label: <Link to='/salesman-damage'> Salesman Damage </Link>,
                key: '/salesman-damage',
                permission_name: 'Salesman',
            },
            {
                label: (
                    <Link to='/salesman-damage-settlement-summary'>
                        {' '}
                        Salesman Damage Settlement{' '}
                    </Link>
                ),
                key: '/salesman-damage-settlement-summary',
                permission_name: 'Salesman',
            },
            {
                label: <Link to='/load-summary'>Load</Link>,
                key: '/load-summary',
                permission_name: 'Load',
            },
            {
                label: <Link to='/suggested-loadSummary'>Suggested Load </Link>,
                key: '/suggested-loadSummary',
                permission_name: 'Load',
            },
            {
                label: <Link to='/unload-summary'>Unload</Link>,
                key: '/unload-summary',
                permission_name: 'Unload',
            },
            {
                label: (
                    <Link to='/advance-collections'>Advance Collections </Link>
                ),
                key: '/advance-collections',
                permission_name: 'Collections',
            },
            {
                label: (
                    <Link to='/credit-debit-notes'>Credit Debit Notes </Link>
                ),
                key: '/credit-debit-notes',
                // permission_name: 'Collections',
            },
            {
                label: <Link to='/settlements'>Settlements </Link>,
                key: '/settlements',
                permission_name: 'Collections',
            },
            {
                label: (
                    <Link to='/stock-adjustment-logs'>
                        {' '}
                        Stock Adjustment Log{' '}
                    </Link>
                ),
                key: '/stock-adjustment-logs',
                permission_name: 'Inventory',
            },
            {
                label: <Link to='/transactions-otp'> Transactions OTP </Link>,
                key: '/transactions-otp',
                permission_name: 'Transactions',
            },
            {
                label: <Link to='/users/log'> Salesmans Logs</Link>,
                key: '/users/log',
                permission_name: 'Salesman',
            },
        ],
    },
];

const SiderView = ({user, toggleCollapsed, isMobile}) => {
    const location = useLocation();
    const history = useHistory();
    const [selectedKey, setSelectedKey] = useState([]);

    const menuItemsFiltered = menuItems.map(item => {
        if (item.children) {
            item.children = item.children.map(el => {
                if (user.role === 'superadmin') {
                    return el;
                } else if (
                    el?.permission_name &&
                    user.role === 'admin' &&
                    !user.permissions.find(
                        p =>
                            p.name?.toLowerCase() ===
                            el.permission_name?.toLowerCase(),
                    )?.read_access
                )
                    return null;
            });
        }
        if (user.role === 'superadmin') {
            return item;
        } else if (
            item.permission_name &&
            user.role === 'admin' &&
            !user.permissions.find(
                p =>
                    p.name?.toLowerCase() ===
                    item.permission_name?.toLowerCase(),
            )?.read_access
        )
            return null;
    });

    const handleOnClick = route => {
        history.push(route.key);
    };

    useEffect(() => {
        const selectedMenuItem = menuItems
            .map(item => (item.children?.length ? item.children : item))
            .flat()
            .find(item => item.key === location.pathname);
        setSelectedKey(selectedMenuItem ? selectedMenuItem.key : []);
    }, [location]);

    return (
        <Sider
            style={{
                overflow: 'auto',
                height: '100%',
                scrollbarWidth: 'none',
                zIndex: 1,
            }}
            width={toggleCollapsed ? 80 : 255}
            collapsible
            collapsed={toggleCollapsed}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
            theme='light'
            trigger={null}
            breakpoint='lg'
            collapsedWidth={isMobile ? 0 : 80}
        >
            <Menu
                selectedKeys={selectedKey}
                mode='inline'
                inlineCollapsed={toggleCollapsed}
                onClick={item => handleOnClick(item)}
            >
                {menuItemsFiltered.map((item, index) => {
                    if (item.children) {
                        return (
                            <SubMenu
                                key={index}
                                icon={item.icon}
                                title={item.label}
                                popupOffset={
                                    item.children.length > 10
                                        ? [0, 150]
                                        : [0, 0]
                                }
                            >
                                {item.children.map((subItem, index) =>
                                    subItem ? (
                                        <Menu.Item key={subItem.key}>
                                            {subItem.label}
                                        </Menu.Item>
                                    ) : null,
                                )}
                            </SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={item.key} icon={item.icon}>
                                {item.label}
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        </Sider>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, null)(SiderView);
