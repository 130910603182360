// CompanySettingsSiderView.js

import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, Link} from 'react-router-dom';
import {Button, Layout, Menu} from 'antd';
import {connect} from 'react-redux';
import {
    CalendarOutlined,
    ClockCircleOutlined,
    DatabaseOutlined,
    FileOutlined,
    LeftOutlined,
    MobileOutlined,
    PayCircleOutlined,
    PrinterOutlined,
    ScheduleOutlined,
    ShopOutlined,
    StockOutlined,
    TransactionOutlined,
} from '@ant-design/icons';

const {Sider} = Layout;

const menuItems = [
    {
        label: <Link to='/company-settings'> Company Info</Link>,
        icon: <ShopOutlined />,
        key: '/company-settings',
    },
    {
        label: (
            <Link to='/company-settings/mobile-settings'>
                Mobile Configuration
            </Link>
        ),
        icon: <MobileOutlined />,
        key: '/company-settings/mobile-settings',
    },
    {
        label: (
            <Link to='/company-settings/transaction-settings'>Transaction</Link>
        ),
        icon: <TransactionOutlined />,
        key: '/company-settings/transaction-settings',
    },
    {
        label: <Link to='/company-settings/print-settings'>Print </Link>,
        icon: <PrinterOutlined />,
        key: '/company-settings/print-settings',
    },
    {
        label: <Link to='/company-settings/route-settings'>Route Journey</Link>,
        icon: <ScheduleOutlined />,
        key: '/company-settings/route-settings',
    },
    {
        label: <Link to='/company-settings/inventory-settings'>Inventory</Link>,
        icon: <StockOutlined />,
        key: '/company-settings/inventory-settings',
    },
    {
        label: <Link to='/company-settings/daytrack-settings'>Day Track</Link>,
        icon: <CalendarOutlined />,
        key: '/company-settings/daytrack-settings',
    },
    {
        label: (
            <Link to='/company-settings/payment-type-settings'>
                Payment Type
            </Link>
        ),
        icon: <PayCircleOutlined />,
        key: '/company-settings/payment-type-settings',
    },
    {
        label: <Link to='/company-settings/eod-settings'>End Of Day</Link>,
        icon: <ClockCircleOutlined />,
        key: '/company-settings/eod-settings',
    },
    {
        label: <Link to='/company-settings/erp-settings'>ERP Integration</Link>,
        icon: <DatabaseOutlined />,
        key: '/company-settings/erp-settings',
    },
    {
        label: <Link to='/company-settings/download-templates'>Templates</Link>,
        icon: <FileOutlined />,
        key: '/company-settings/download-templates',
    },
    {
        label: (
            <Link to='/company-settings/custom-document-code'>
                Custom Document Code
            </Link>
        ),
        icon: <FileOutlined />,
        key: '/company-settings/custom-document-code',
    },
];

const CompanySettingsSiderView = ({user, toggleCollapsed, isMobile}) => {
    const location = useLocation();
    const history = useHistory();

    const [selectedKey, setSelectedKey] = useState([]);

    const menuItemsFiltered = menuItems.map(item => {
        if (item.children) {
            item.children = item.children.map(el => {
                if (user.role === 'superadmin') {
                    return el;
                } else if (
                    el?.permission_name &&
                    user.role === 'admin' &&
                    !user.permissions.find(
                        p =>
                            p.name?.toLowerCase() ===
                            el.permission_name?.toLowerCase(),
                    )?.read_access
                )
                    return null;
            });
        }
        if (user.role === 'superadmin') {
            return item;
        } else if (
            item.permission_name &&
            user.role === 'admin' &&
            !user.permissions.find(
                p =>
                    p.name?.toLowerCase() ===
                    item.permission_name?.toLowerCase(),
            )?.read_access
        )
            return null;
    });

    const handleOnClick = route => {
        history.push(route.key);
    };

    useEffect(() => {
        const selectedMenuItem = menuItems
            .map(item => (item.children?.length ? item.children : item))
            .flat()
            .find(item => item.key === location.pathname);
        setSelectedKey(selectedMenuItem ? selectedMenuItem.key : []);
    }, [location]);
    // Implement your handleOnClick function

    return (
        <Sider
            style={{
                overflow: 'auto',
                height: '100%',
                scrollbarWidth: 'none',
                zIndex: 1,
            }}
            collapsible
            width={toggleCollapsed ? 80 : 255}
            collapsed={toggleCollapsed}
            theme='light'
            trigger={
                <Button
                    icon={<LeftOutlined />}
                    onClick={() => history.push('/')}
                />
            }
            breakpoint='lg'
            collapsedWidth={isMobile ? 0 : 80}
        >
            {/* Main menu items */}
            <Menu
                selectedKeys={selectedKey}
                mode='inline'
                items={menuItemsFiltered}
                inlineCollapsed={toggleCollapsed}
                onClick={item => handleOnClick(item)}
            />
        </Sider>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, null)(CompanySettingsSiderView);
